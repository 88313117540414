import { useEffect, useRef, useState } from 'react';

import { Box, Card, CardMedia, Dialog, Grid, Tooltip, Typography } from '@mui/material';
import { common } from '@mui/material/colors';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { Transition } from '@/components/common/Transition';
import DocumentCourse from '@/components/Learning/DocumentCourse';
import { CourseTypes, GroupedCourseTypes } from '@/types/management';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface DetailCourseProps {
  openDialogDetail: boolean;
  onDialogDetailChange: (newValue: boolean) => void;
  detail: CourseTypes;
  typeSelected: string;
  listCourses: GroupedCourseTypes[];
}

const DetailCourse: React.FC<DetailCourseProps> = ({
  openDialogDetail,
  onDialogDetailChange,
  detail,
  typeSelected,
  listCourses,
}) => {
  const { t } = useTranslation();

  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoKey, setVideoKey] = useState(0);
  const [showDescription, setShowDescription] = useState(false);
  const [listCoursesByType, setListCoursesByType] = useState<CourseTypes[]>([]);
  const [currentCourse, setCurrentCourse] = useState<CourseTypes>(detail);

  useEffect(() => {
    if (openDialogDetail) {
      setVideoKey((prevKey) => prevKey + 1);
    } else if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  }, [openDialogDetail]);

  useEffect(() => {
    const filteredCourses = listCourses.find((group) => group.type === typeSelected);
    setListCoursesByType(filteredCourses ? filteredCourses.list_course : []);
  }, []);

  useEffect(() => {
    setCurrentCourse(detail);
  }, [openDialogDetail]);

  return (
    <>
      <Dialog
        open={openDialogDetail}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          onDialogDetailChange(false);
        }}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: { xs: '95vw', md: '80vw' },
            },
          },
          overflow: 'visible',
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          },
        }}
      >
        <Box sx={{ height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.8)', padding: 2 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={10}>
              <CardMedia
                key={videoKey}
                component="video"
                ref={videoRef}
                autoPlay
                src={`${currentCourse.url_storage}/${currentCourse.bucket_name}/${currentCourse.detail_link.link_video}`}
                controls
              />
              <Typography
                sx={{
                  color: common.white,
                  padding: 1.5,
                  textTransform: 'capitalize',
                  fontSize: { xs: '18px', md: '25px' },
                }}
              >
                {currentCourse?.title}
              </Typography>
              {!_.isEmpty(currentCourse.description) && (
                <Box
                  sx={{ marginLeft: 1.5, cursor: 'pointer' }}
                  onClick={() => {
                    setShowDescription(!showDescription);
                  }}
                >
                  {showDescription ? (
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: 500, color: 'rgba(255,255,255,0.8)' }}
                      dangerouslySetInnerHTML={{ __html: currentCourse.description }}
                    />
                  ) : (
                    <>
                      <Typography
                        variant="body2"
                        sx={{
                          color: 'rgba(255,255,255,0.8)',
                          fontWeight: 500,
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          WebkitLineClamp: 3,
                          textOverflow: 'ellipsis',
                        }}
                        dangerouslySetInnerHTML={{ __html: currentCourse.description }}
                      />
                      <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.8)', fontStyle: 'italic' }}>
                        Xem thêm
                      </Typography>
                    </>
                  )}
                </Box>
              )}
              {detail.detail_link.link_document && (
                <Box sx={{ mt: 2, padding: 2 }}>
                  <Typography sx={{ color: 'rgba(255,255,255,0.8)' }}>
                    {t('table.courseManagement.document')}
                  </Typography>
                  <DocumentCourse course={currentCourse} />
                </Box>
              )}
            </Grid>
            <Grid item xs={12} lg={2}>
              <Box sx={{ padding: 1 }}>
                <Typography sx={{ color: 'rgba(255,255,255,0.8)', fontWeight: 600 }}>
                  {t('management.training.relatedCourses')}
                </Typography>
                <Box sx={{ padding: 2 }}>
                  {listCoursesByType?.map((course: CourseTypes) => (
                    <Box key={course.id} sx={{ paddingY: 1.5 }}>
                      <Tooltip title={course.title}>
                        <Card
                          sx={{
                            width: { xs: '60% !important', sm: '40% !important', lg: '100% !important' },
                            borderRadius: 2,
                            transition: 'all 0.2s',
                            backgroundColor: 'background.main',
                            cursor: 'pointer',
                            '&:hover': {
                              transform: 'translateY(-4px)',
                              boxShadow: '0 4px 8px #0000001a',
                            },
                            minHeight: '100px',
                            maxHeight: '120px',
                            position: 'relative',
                            overflow: 'hidden',
                            '&::before': {
                              content: '""',
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%',
                              backgroundColor: 'rgba(0, 0, 0, 0.5)',
                              zIndex: 1,
                              transition: 'opacity 0.3s ease-in-out',
                            },
                            '&:hover::before': {
                              opacity: 0,
                            },
                          }}
                          onClick={() => {
                            setCurrentCourse(course);
                          }}
                        >
                          <Box sx={{ position: 'relative' }}>
                            <CardMedia
                              component="img"
                              alt="green iguana"
                              image={`${course.url_storage}/${course.bucket_name}/${course.detail_link.link_thumbnail}`}
                              sx={{ height: '150px' }}
                            />
                          </Box>
                        </Card>
                      </Tooltip>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
};

export default DetailCourse;
