import { Box, Dialog, DialogTitle, DialogContent, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Transition } from '@/components/common/Transition';
import { AttachmentTypes } from '@/types/phishing';
import { fDateTime } from '@/utils/function';

export const getFilenameMalware = (filename: string) => {
  const regex = /media\/attachments\/(.+)/;
  const match = regex.exec(filename);
  if (match) {
    const fileName = match[1];
    return fileName;
  }
  return null;
};
interface DetailMalwareProps {
  openDialogDetail: boolean;
  setOpenDialogDetail: (newValue: boolean) => void;
  malware: AttachmentTypes;
}

const DetailMalware: React.FC<DetailMalwareProps> = ({ openDialogDetail, setOpenDialogDetail, malware }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <Dialog
        open={openDialogDetail}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setOpenDialogDetail(false);
        }}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '800px',
              backgroundColor: 'background.main',
              border: `1px solid ${theme.palette.divider}`,
            },
          },
          overflow: 'visible',
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <DialogTitle>
          <Typography sx={{ fontSize: '2rem', marginTop: 1 }}>{t('asm.phishing.detailMalware')}</Typography>
        </DialogTitle>

        <DialogContent>
          <Box
            sx={{
              marginTop: 1,
              backgroundColor: 'background.main',
              padding: 2,
              borderRadius: 1,
              boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
            }}
          >
            <Box
              sx={{
                padding: 1.5,
                display: 'flex',
              }}
            >
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {t('asm.phishing.malwareName')}
              </Typography>
              <Typography variant="body2" sx={{ marginLeft: 1.5 }}>
                {malware.name}
              </Typography>
            </Box>
            <Box
              sx={{
                padding: 1.5,
                display: 'flex',
              }}
            >
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {t('asm.phishing.description')}
              </Typography>
              <Typography variant="body2" sx={{ marginLeft: 1.5 }}>
                {malware.description}
              </Typography>
            </Box>
            <Box
              sx={{
                padding: 1.5,
                display: 'flex',
              }}
            >
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {t('asm.phishing.fileName')}
              </Typography>
              <Typography variant="body2" sx={{ marginLeft: 1.5 }}>
                {getFilenameMalware(malware.file)}
              </Typography>
            </Box>
            <Box
              sx={{
                padding: 1.5,
                display: 'flex',
              }}
            >
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {t('asm.phishing.uploader')}
              </Typography>
              <Typography variant="body2" sx={{ marginLeft: 1.5 }}>
                {malware.uploader?.username}
              </Typography>
            </Box>
            <Box
              sx={{
                padding: 1.5,
                display: 'flex',
              }}
            >
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {t('asm.phishing.createAt')}
              </Typography>
              <Typography variant="body2" sx={{ marginLeft: 1.5 }}>
                {malware?.created_at ? fDateTime(malware?.created_at) : ''}
              </Typography>
            </Box>
            <Box
              sx={{
                padding: 1.5,
                display: 'flex',
              }}
            >
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {t('asm.phishing.modifiedAt')}
              </Typography>
              <Typography variant="body2" sx={{ marginLeft: 1.5 }}>
                {malware?.modified_at ? fDateTime(malware?.modified_at) : ''}
              </Typography>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default DetailMalware;
