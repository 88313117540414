import { useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Box, Button, Dialog, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

import { Transition } from '@/components/common/Transition';
import { formatDateAndHours } from '@/configs/date';
import useManagenent from '@/Hooks/api/useManagenent';
import { MonitorType } from '@/types/management';

interface UpdateMonitorProps {
  openDialogUpdate: boolean;
  onDialogUpdateChange: (newValue: boolean) => void;
  detail: MonitorType;
  handleGetList: () => Promise<void>;
}

const UpdateMonitor: React.FC<UpdateMonitorProps> = ({
  openDialogUpdate,
  onDialogUpdateChange,
  detail,
  handleGetList,
}) => {
  const { t } = useTranslation();
  const { handleUpdateMonitor } = useManagenent();
  const [searchParams, setSearchParams] = useSearchParams();
  const contentRef = useRef<HTMLDivElement>(null);

  const [inputValueKey, setInputValueKey] = useState('');
  const [inputValueMail, setInputValueMail] = useState('');

  const schema = yup.object().shape({
    name: yup.string().required(t('validation.fieldRequired')),
    key: yup.array().of(yup.string().required()).min(1, t('validation.fieldRequired')),
    email: yup
      .array()
      .of(yup.string().required(t('validation.fieldRequired')).email(t('validation.invalidEmail')))
      .min(1, t('validation.fieldRequired')),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      key: [],
      email: [],
    },
  });

  useEffect(() => {
    if (openDialogUpdate && contentRef.current) {
      contentRef.current.scrollTop = 0;
      setInputValueKey('');
      setInputValueMail('');
    }
    if (detail) {
      reset({
        name: detail.name,
        key: detail.keywords?.map((item) => item.keyword),
        email: detail.emails?.map((item) => item.email),
      });
    }
  }, [openDialogUpdate, detail]);

  const updateSuccess = () => {
    onDialogUpdateChange(false);
    if (searchParams.get('page') === '1') {
      handleGetList();
    } else {
      setSearchParams({ page: '1' });
    }
  };
  const onSubmit = async (data: any) => {
    const params = {
      name: data.name,
      keywords: data.key,
      emails: data.email,
    };
    await handleUpdateMonitor(detail.id, params, updateSuccess);
  };

  const handleInputKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    value: string[],
    onChange: (newValues: string[]) => void,
    inputValue: string,
    setInputValue: (value: string) => void,
  ) => {
    if (event.key === 'Enter' && inputValue) {
      event.preventDefault();
      onChange([...value, inputValue]);
      setInputValue('');
    }
  };

  return (
    <>
      <Dialog
        open={openDialogUpdate}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          onDialogUpdateChange(false);
        }}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: { xs: '90vw', md: '60vw' },
            },
          },
          overflow: 'visible',
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
          },
        }}
      >
        <DialogTitle>
          <Typography sx={{ fontSize: '2rem', marginTop: 1 }}>
            {t('management.keywordMonitor.updateMonitor')}
          </Typography>
        </DialogTitle>
        <DialogContent ref={contentRef}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ marginTop: 2 }}>
              <Controller
                name="name"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    key={detail?.name}
                    type="text"
                    variant="outlined"
                    style={{ width: '100%', marginTop: '3px' }}
                    label={t('placeholder.groupName')}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                  />
                )}
              />
            </Box>
            <Box
              sx={{
                mt: 2,
                padding: 2,
                width: '100%',
                borderRadius: 0.5,
                flexDirection: 'column',
                backgroundColor: 'action.hover',
              }}
            >
              <Controller
                name="key"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    multiple
                    options={[]}
                    freeSolo
                    inputValue={inputValueKey}
                    onInputChange={(event, newValue) => {
                      if (event?.type !== 'blur') {
                        setInputValueKey(newValue);
                      }
                    }}
                    onChange={(event, value) => {
                      field.onChange(value);
                    }}
                    value={field.value || []}
                    sx={{
                      '.MuiChip-label': {
                        fontSize: '1.2rem',
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t('placeholder.keyword')}
                        error={!!errors.key}
                        helperText={errors.key?.message}
                        onKeyDown={
                          (event: React.KeyboardEvent<HTMLInputElement>) =>
                            handleInputKeyDown(
                              event,
                              field.value || [],
                              (newValue) => field.onChange(newValue),
                              inputValueKey,
                              setInputValueKey,
                            )
                          // eslint-disable-next-line react/jsx-curly-newline
                        }
                      />
                    )}
                  />
                )}
              />
              <Typography sx={{ fontSize: '1.2rem', color: 'text.disabled', mt: 1 }}>{t('smcc.enterToAdd')}</Typography>
            </Box>
            <Box
              sx={{
                mt: 4,
                padding: 2,
                width: '100%',
                borderRadius: 0.5,
                flexDirection: 'column',
                backgroundColor: 'action.hover',
              }}
            >
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    multiple
                    options={[]}
                    freeSolo
                    inputValue={inputValueMail}
                    onInputChange={(event, newValue) => {
                      if (event?.type !== 'blur') {
                        setInputValueMail(newValue);
                      }
                    }}
                    onChange={(event, value) => {
                      field.onChange(value);
                    }}
                    value={field.value || []}
                    sx={{
                      '.MuiChip-label': {
                        fontSize: '1.2rem',
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t('placeholder.email')}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        onKeyDown={
                          (event: React.KeyboardEvent<HTMLInputElement>) =>
                            handleInputKeyDown(
                              event,
                              field.value || [],
                              (newValue) => field.onChange(newValue),
                              inputValueMail,
                              setInputValueMail,
                            )
                          // eslint-disable-next-line react/jsx-curly-newline
                        }
                      />
                    )}
                  />
                )}
              />
              <Typography sx={{ fontSize: '1.2rem', color: 'text.disabled', mt: 1 }}>{t('smcc.enterToAdd')}</Typography>
            </Box>
            <Box sx={{ marginTop: 2 }}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                {t('table.apiKeyManagement.createAt')}
              </Typography>
              <TextField
                type="text"
                variant="outlined"
                fullWidth
                defaultValue={detail?.created_at ? formatDateAndHours(detail.created_at) : '-'}
                value={detail?.created_at ? formatDateAndHours(detail.created_at) : '-'}
                disabled
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Button type="submit" variant="contained" color="primary" sx={{ mt: 3 }}>
                {t('action.update')}
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default UpdateMonitor;
