import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Chip,
  useTheme,
  Autocomplete,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

import CancelButton from '@/components/common/Button/CancelButton';
import PrimaryButton from '@/components/common/Button/PrimaryButton';
import { Transition } from '@/components/common/Transition';
import ConfirmCancelModal from '@/components/Modal/ConfirmCancelModal';
import usePhishing from '@/Hooks/api/usePhishing';
import { CampaignTypes } from '@/types/phishing';
import { fDateTime } from '@/utils/function';

interface UpdateCampaignProps {
  openDialogUpdate: boolean;
  onOpenDialogUpdateChange: (newValue: boolean) => void;
  campaign: CampaignTypes;
  handleGetListCampaign: () => void;
}

const UpdateCampaign: React.FC<UpdateCampaignProps> = ({
  openDialogUpdate,
  onOpenDialogUpdateChange,
  campaign,
  handleGetListCampaign,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { handleUpdateDetailCampaign } = usePhishing();
  const [searchParams, setSearchParams] = useSearchParams();

  const [inputValue, setInputValue] = useState('');
  const [openDialogCancel, setOpenDialogCancel] = useState(false);

  const validationSchema = yup.object().shape({
    name: !campaign.name ? yup.string().required(t('validation.fieldRequired')) : yup.string(),
    description: !campaign.description ? yup.string().required(t('validation.fieldRequired')) : yup.string(),
    email: yup
      .array()
      .of(yup.string().required(t('validation.fieldRequired')).email(t('validation.invalidEmail')))
      .min(1, t('validation.fieldRequired')),
    content: !campaign.phishing_content ? yup.string().required(t('validation.fieldRequired')) : yup.string(),
    landingPage: !campaign.landing_page ? yup.string().required(t('validation.fieldRequired')) : yup.string(),
    subject: !campaign.subject ? yup.string().required(t('validation.fieldRequired')) : yup.string(),
    senderName: !campaign.sender_name ? yup.string().required(t('validation.fieldRequired')) : yup.string(),
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { isDirty, errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: campaign.user_list,
    },
  });

  useEffect(() => {
    if (campaign) {
      reset({
        name: campaign.name,
        description: campaign.description,
        email: campaign.user_list,
        content: campaign.phishing_content,
        landingPage: campaign.landing_page,
        subject: campaign.subject,
        senderName: campaign.sender_name,
      });
    }
  }, [campaign, reset]);

  const updateCampaignSuccess = () => {
    onOpenDialogUpdateChange(false);
    reset();
    if (Array.from(searchParams.keys()).length === 1 && searchParams.get('page') === '1') {
      handleGetListCampaign();
    } else {
      const newParams = new URLSearchParams(searchParams);
      newParams.set('page', '1');
      setSearchParams(newParams);
    }
  };

  const onSubmit = (data: any) => {
    const params = {
      name: data.name || campaign.name,
      description: data.description || campaign.description,
      user_list: data.email,
      phishing_content: data.content || campaign.phishing_content,
      landing_page: data.landingPage || campaign.landing_page,
      subject: data.subject || campaign.subject,
      sender_name: data.senderName || campaign.sender_name,
      attachments: [],
    };
    handleUpdateDetailCampaign(campaign.id, params, updateCampaignSuccess);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputValue) {
      event.preventDefault();
      setValue('email', [...(getValues('email') || []), inputValue], { shouldValidate: true });
      setInputValue('');
    }
  };

  const handleCancelClick = () => {
    if (isDirty) {
      setOpenDialogCancel(true);
    } else {
      onOpenDialogUpdateChange(false);
    }
  };

  const handleCancel = () => {
    onOpenDialogUpdateChange(false);
    setOpenDialogCancel(false);
    reset();
  };

  return (
    <>
      <Dialog
        open={openDialogUpdate}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          onOpenDialogUpdateChange(false);
        }}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '800px',
              backgroundColor: 'background.main',
              border: `1px solid ${theme.palette.divider}`,
            },
          },
          overflow: 'visible',
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
          },
        }}
      >
        <DialogTitle>
          <Typography sx={{ fontSize: '2rem', marginTop: 1 }}>{t('asm.phishing.updateCampaign')}</Typography>
        </DialogTitle>

        <DialogContent>
          <Box sx={{ display: 'flex' }}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t('asm.phishing.createAt')}
            </Typography>
            <Typography variant="body2" sx={{ ml: 1, color: 'text.primary' }}>
              {campaign?.created_at ? fDateTime(campaign?.created_at) : ''}
            </Typography>
          </Box>
          {(campaign?.created_at ? fDateTime(campaign?.created_at) : '') !==
            (campaign?.modified_at ? fDateTime(campaign?.modified_at) : '') && (
            <Box sx={{ display: 'flex', mt: 1 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {t('asm.phishing.modifiedAt')}
              </Typography>
              <Typography variant="body2" sx={{ ml: 1, color: 'text.primary' }}>
                {campaign?.modified_at ? fDateTime(campaign?.modified_at) : ''}
              </Typography>
            </Box>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
              <Controller
                name="name"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    key={campaign.name}
                    defaultValue={campaign.name}
                    type="text"
                    variant="outlined"
                    style={{ width: '100%', marginTop: '3px' }}
                    label={t('placeholder.campaignName')}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                  />
                )}
              />
            </Box>
            <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
              <Controller
                name="description"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    key={campaign.description}
                    defaultValue={campaign.description}
                    type="text"
                    variant="outlined"
                    style={{ width: '100%', marginTop: '3px' }}
                    label={t('placeholder.description')}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                  />
                )}
              />
            </Box>
            <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    multiple
                    id="tags-filled"
                    options={[]}
                    freeSolo
                    value={field.value || []}
                    onChange={(_, value) => field.onChange(value)}
                    getOptionLabel={(option) => option}
                    renderTags={
                      (tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip variant="outlined" label={option} {...getTagProps({ index })} key={option} />
                        ))
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    sx={{
                      mt: 3,
                      '.MuiChip-label': {
                        fontSize: '1.4rem',
                      },
                    }}
                    inputValue={inputValue}
                    onInputChange={(_, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={t('placeholder.keyword')}
                        onKeyDown={handleKeyDown}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                      />
                    )}
                  />
                )}
              />

              <Typography sx={{ fontSize: '1.2rem', color: 'text.disabled', mt: 1 }}>{t('smcc.enterToAdd')}</Typography>
            </Box>
            <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
              <Controller
                name="content"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    key={campaign.phishing_content}
                    defaultValue={campaign.phishing_content}
                    type="text"
                    variant="outlined"
                    multiline
                    style={{ width: '100%', marginTop: '3px' }}
                    label={t('placeholder.content')}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                  />
                )}
              />
            </Box>
            <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
              <Controller
                name="landingPage"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    key={campaign.landing_page}
                    defaultValue={campaign.landing_page}
                    type="text"
                    variant="outlined"
                    style={{ width: '100%', marginTop: '3px' }}
                    label={t('placeholder.landingPage')}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                  />
                )}
              />
            </Box>
            <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
              <Controller
                name="subject"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    key={campaign.subject}
                    defaultValue={campaign.subject}
                    type="text"
                    variant="outlined"
                    style={{ width: '100%', marginTop: '3px' }}
                    label={t('placeholder.subject')}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                  />
                )}
              />
            </Box>
            <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
              <Controller
                name="senderName"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    key={campaign.sender_name}
                    defaultValue={campaign.sender_name}
                    type="text"
                    variant="outlined"
                    style={{ width: '100%', marginTop: '3px' }}
                    label={t('placeholder.sender')}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                  />
                )}
              />
            </Box>
          </form>
          <Box sx={{ margin: 'auto', mt: 2, display: 'flex', justifyContent: 'flex-end', mr: 3 }}>
            <PrimaryButton handleClick={handleSubmit(onSubmit)} label={t('action.update')} />
            <CancelButton handleClick={handleCancelClick} />
          </Box>
          <ConfirmCancelModal
            openDialogCancel={openDialogCancel}
            onOpenDialogCancelChange={setOpenDialogCancel}
            handleCancel={handleCancel}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
export default UpdateCampaign;
