import { useEffect, useState } from 'react';

import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import CloseIcon from '@mui/icons-material/Close';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PublicIcon from '@mui/icons-material/Public';
import RadarIcon from '@mui/icons-material/Radar';
import SecurityIcon from '@mui/icons-material/Security';
import StorageIcon from '@mui/icons-material/Storage';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { Box, Drawer, Typography, useTheme, IconButton, Toolbar, Collapse, List } from '@mui/material';
import * as _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';

interface IModalMenu {
  openModal: boolean;
  handleCloseModal: () => void;
}

const ModalMenu: React.FC<IModalMenu> = ({ openModal, handleCloseModal }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const initialContentsOsint = [
    {
      name: 'urlscan',
      label: 'Urlscan',
      src: '/osint/urlscan',
      active: true,
    },
    {
      name: 'fofa',
      label: 'Fofa',
      src: '/osint/fofa',
      active: false,
    },
    {
      name: 'theharvester',
      label: 'Theharvester',
      src: '/osint/theharvester',
      active: false,
    },
    {
      name: 'whois',
      label: 'Whois',
      src: '/osint/whois',
      active: false,
    },
    {
      name: 'mailhunter',
      label: 'Mailhunter',
      src: '/osint/mailhunter',
      active: false,
    },
  ];

  const [contents, setContents] = useState([
    {
      name: 'Score',
      label: 'Score',
      icon: <AutoGraphIcon />,
      src: '/score',
      active: true,
    },
    {
      name: 'asset',
      label: 'Asset',
      icon: <StorageIcon />,
      src: '/asset',
      active: false,
    },
    {
      name: 'Information',
      label: 'Information',
      icon: <PublicIcon />,
      src: '/info',
      active: false,
    },
    {
      name: 'Subdomains',
      label: 'Subdomains',
      icon: <ViewModuleIcon />,
      src: '/subdomains',
      active: false,
    },
    {
      name: 'ScanVul',
      label: 'Vulnerability',
      icon: <SecurityIcon />,
      src: '/vul',
      active: false,
    },
    {
      name: 'Spider',
      label: 'Spider',
      icon: <AccountTreeIcon />,
      src: '/spider',
      active: false,
    },
    {
      name: 'report',
      label: 'Report',
      icon: <AssessmentIcon />,
      src: '/report',
      active: false,
    },
    // {
    //   name: 'nikto',
    //   label: 'Nikto',
    //   icon: <RadarIcon />,
    //   src: '/nikto',
    //   active: false,
    // },
  ]);
  const [contentsOsint, setContentsOsint] = useState(initialContentsOsint);
  const [openOsint, setOpenOsint] = useState(false);

  const handleContentClick = (contentSrc: string) => {
    navigate(contentSrc);
    handleCloseModal();
  };

  const handleClickOsint = () => {
    setOpenOsint(!openOsint);
  };

  const handleOsintClick = (contentSrc: string) => {
    const currentParams = location.search;
    navigate(`${contentSrc}${currentParams}`);
    handleCloseModal();
  };

  useEffect(() => {
    const updatedContents = contents.map((content) =>
      location.pathname === content.src ? { ...content, active: true } : { ...content, active: false },
    );
    setContents(updatedContents);

    const updatedContentsOsint = contentsOsint.map((content) =>
      location.pathname === content.src ? { ...content, active: true } : { ...content, active: false },
    );
    setContentsOsint(updatedContentsOsint);

    if (location.pathname.includes('osint/')) {
      setOpenOsint(true);
    }
  }, [location.pathname]);

  return (
    <Drawer
      open={openModal}
      onClose={() => {
        handleCloseModal();
      }}
      anchor="left"
      transitionDuration={{ appear: 900, enter: 300, exit: 400 }}
      sx={{ zIndex: theme.zIndex.drawer + 1, paddingBlock: 3 }}
    >
      <Box
        sx={{
          width: '100vw',
          padding: 2,
          height: '100%',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ fontWeight: 600, fontSize: '22px' }}>Menu</Typography>
          <IconButton
            onClick={() => {
              handleCloseModal();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ marginTop: 2 }}>
          {contents.map((content) => (
            <Toolbar
              key={content.name}
              onClick={() => {
                handleContentClick(content.src);
              }}
              sx={{
                mt: 1,
                borderRadius: 1,
                transition: 'background-color 0.4s ease, color 0.4s ease',
                width: '100%',
                paddingLeft: '35%',
                minHeight: '30px',
                backgroundColor: content.active ? 'rgb(3, 201, 215)' : 'initial',
                '& .MuiTypography-root': {
                  color: content.active ? 'common.white' : 'text.secondary',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography sx={{ fontSize: '2.5rem', color: content.active ? 'common.white' : 'text.secondary' }}>
                  {content.icon}
                </Typography>
                <Typography sx={{ ml: 1 }}>{content.label}</Typography>
              </Box>
            </Toolbar>
          ))}
        </Box>
        <Box
          onClick={() => {
            handleClickOsint();
          }}
          sx={{
            mt: 1,
            borderRadius: 1,
            transition: 'background-color 0.4s ease, color 0.4s ease',
            width: '100%',
            paddingLeft: '35%',
            minHeight: '30px',
            display: 'flex',
            alignItems: 'center',
            '& .MuiTypography-root': {
              color: openOsint ? 'common.white' : 'text.secondary',
            },
            '& .MuiSvgIcon-root': {
              color: openOsint ? 'common.white' : 'text.secondary',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography sx={{ fontSize: '2rem' }}>
              <TravelExploreIcon />
            </Typography>
            <Typography
              sx={{
                ml: 1.5,
                whiteSpace: 'nowrap',
              }}
            >
              OSINT
            </Typography>
          </Box>
          <Box sx={{ ml: 4 }}>{openOsint ? <ExpandLess /> : <ExpandMore />}</Box>
        </Box>
        <Collapse in={openOsint} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ paddingLeft: '38%' }}>
            {contentsOsint.map((content) => (
              <Box
                key={content.name}
                onClick={() => {
                  handleOsintClick(content.src);
                }}
                sx={{
                  ml: 2,
                  mt: 0.5,
                  padding: '5px 0px',
                  backgroundColor: content.active ? 'action.hover' : 'initial',
                  borderRadius: 0.5,
                  transition: 'background-color 0.4s ease, color 0.4s ease',
                  justifyContent: 'center',
                  '& .MuiTypography-root': {
                    color: content.active ? '#E9B02F' : 'text.secondary',
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: '1.4rem',
                    ml: 1.5,
                    whiteSpace: 'nowrap',
                  }}
                >
                  {content.label}
                </Typography>
              </Box>
            ))}
          </List>
        </Collapse>
      </Box>
    </Drawer>
  );
};
export default ModalMenu;
