import { useState, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import SearchIcon from '@mui/icons-material/Search';
import { Box, TextField, Typography, SelectChangeEvent, LinearProgress } from '@mui/material';
import * as _ from 'lodash';
import { Helmet } from 'react-helmet';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import FormSelectDomain from '@/components/common/FormSelectDomain';
import ScanTime from '@/components/common/ScanTime';
import { useWebSocketContext } from '@/contexts/WebSocketContext';
import useAsm from '@/Hooks/api/useAsm';
import { WSDataType, WSType } from '@/types/Asm';
import { decrypt, encrypt } from '@/utils/crypto';

const NiktoScan = () => {
  const domainActive = localStorage.getItem('domainActive')
    ? decrypt(localStorage.getItem('domainActive') as string)
    : '';
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));
  const { dataWebSocket } = useWebSocketContext();
  const { handleNiktoScan } = useAsm();
  const { t } = useTranslation();

  const [niktoResult, setNiktoResult] = useState<WSDataType>({ data: '', update_time: '' });
  const [domain, setDomain] = useState(domainActive);
  const [loadingState, setLoadingState] = useState(false);

  const filteredList = dataWebSocket.filter((item: WSType) => item.domain === domain);

  useEffect(() => {
    if (inforUser?.role !== 'super admin') {
      const niktoWebsocket = filteredList.find((item: WSType) => item.title === 'scanNikto');
      if (!_.isEmpty(niktoWebsocket?.data?.data)) {
        setNiktoResult({
          data: niktoWebsocket?.data?.data || '',
          update_time: niktoWebsocket?.update_time,
        });
        setLoadingState(false);
      } else {
        setLoadingState(true);
      }
    }
  }, [domain, dataWebSocket]);

  const handleChangeDomain = async (event: SelectChangeEvent) => {
    setDomain(event.target.value);
    localStorage.setItem('domainActive', await encrypt(event.target.value));
  };

  const validationSchema = yup.object({
    domainKey: yup
      .string()
      .required(t('validation.fieldRequired'))
      .matches(/^(?!:\/\/)([a-zA-Z0-9-]+\.)+([a-zA-Z]{2,})(:[0-9]+)?(\/[^\s]*)?$/, t('validation.invalidDomain')),
  });

  const { control, handleSubmit } = useForm({ resolver: yupResolver(validationSchema) });

  const onSubmit = async (data: { domainKey: string }) => {
    setNiktoResult({
      data: '',
      update_time: '',
    });
    const dataRes = await handleNiktoScan({ domain: data.domainKey });
    setNiktoResult({
      data: dataRes?.data || [],
      update_time: dataRes?.update_time,
    });
  };

  const handleKeyDown = (event: { keyCode: number }) => {
    if (event.keyCode === 13) {
      handleSubmit(onSubmit);
    }
  };

  return (
    <>
      <Helmet>
        <title>Scan Nikto - Attack Surface Management</title>
      </Helmet>
      <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.primary' }}>
        Nikto scan web server
      </Typography>
      <Box>
        {inforUser?.role === 'super admin' ? (
          <Box sx={{ width: '100%', mt: 3 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="domainKey"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    style={{ width: '100%' }}
                    placeholder={t('placeholder.mainDomain')}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                    onKeyDown={handleKeyDown}
                    sx={{
                      '.MuiInputBase-input': {
                        padding: '12px 12px',
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <SearchIcon
                          onClick={handleSubmit(onSubmit)}
                          sx={{
                            fontSize: '2rem',
                            mr: 1,
                            '&:hover': {
                              cursor: 'pointer',
                            },
                          }}
                        />
                      ),
                    }}
                  />
                )}
              />
            </form>
          </Box>
        ) : (
          <Box sx={{ width: '100%', mt: 2, display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex' }}>
              <FormSelectDomain domain={domain} handleChangeDomain={handleChangeDomain} />
            </Box>

            {!_.isEmpty(niktoResult.data && niktoResult?.update_time) && (
              <ScanTime updateTime={niktoResult?.update_time} />
            )}
          </Box>
        )}

        {loadingState && (
          <>
            <Typography
              variant="body2"
              sx={{ marginTop: 3, textAlign: 'center', color: 'error.main', fontWeight: 600 }}
            >
              {t('notify.scanInProgress')}
            </Typography>
            <br />
            <Box sx={{ width: '100%', marginTop: 3 }}>
              <LinearProgress color="info" />
            </Box>
          </>
        )}

        {!_.isEmpty(niktoResult.data) && !loadingState && (
          <>
            <Box
              sx={{
                mt: 3,
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: 'action.hover',
                padding: 2,
                borderRadius: 0.5,
              }}
            >
              <Typography variant="body2" sx={{ whiteSpace: 'pre-line', lineBreak: 'anywhere' }}>
                {niktoResult.data}
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};
export default NiktoScan;
