import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  TooltipProps,
  tooltipClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { IconHelp } from '@/assets/svg/svgIcon';
import CancelButton from '@/components/common/Button/CancelButton';
import PrimaryButton from '@/components/common/Button/PrimaryButton';
import { Transition } from '@/components/common/Transition';
import ConfirmCancelModal from '@/components/Modal/ConfirmCancelModal';
import usePhishing from '@/Hooks/api/usePhishing';
import { GeneratedContent } from '@/types/phishing';

import CreateCampaign from './CreateCampaign';

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#5b5b5b',
    color: 'rgba(255, 255, 255, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #dadde9',
  },
}));

type FormData = {
  content: string;
  url: string;
  senderName: string;
};

interface GetContentProps {
  openDialogGetContent: boolean;
  onOpenDialogGetContentChange: (newValue: boolean) => void;
  handleGetListCampaign: () => void;
}

const GetContent: React.FC<GetContentProps> = ({
  openDialogGetContent,
  onOpenDialogGetContentChange,
  handleGetListCampaign,
}) => {
  const { t } = useTranslation();
  const { handleGetContent } = usePhishing();

  const [generatedContent, setGeneratedContent] = useState<GeneratedContent>({
    content: '',
    subject: '',
    senderName: '',
  });
  const [openDialogCreateCampaign, setOpenDialogCreateCampaign] = useState(false);
  const [openDialogCancel, setOpenDialogCancel] = useState(false);

  const steps = [t('asm.phishing.getContentForCampaign'), t('asm.phishing.createCampaign')];

  const validationSchema = yup.object().shape({
    content: yup.string().required(t('validation.fieldRequired')),
    url: yup
      .string()
      .required(t('validation.fieldRequired'))
      .matches(/^(https?|ftp):\/\/[^\s\/$.?#].[^\s]*$/, t('validation.invalidUrl')),
    senderName: yup.string().required(t('validation.fieldRequired')),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      content: '',
      url: '',
      senderName: '',
    },
  });

  const getContentSuccess = () => {
    setOpenDialogCreateCampaign(true);
    onOpenDialogGetContentChange(false);
    reset();
  };

  const onSubmit = async (data: FormData) => {
    const params = { content: data.content, url: data.url, send_name: data.senderName };
    const res = await handleGetContent(params, getContentSuccess);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { content, subject, sender_name } = res;
    setGeneratedContent({
      content,
      subject,
      senderName: sender_name,
    });
  };

  const handleKeyDown = (event: { keyCode: number }) => {
    if (event.keyCode === 13) {
      handleSubmit(onSubmit)();
    }
  };

  const handleCancelClick = () => {
    if (isDirty) {
      setOpenDialogCancel(true);
    } else {
      onOpenDialogGetContentChange(false);
    }
  };

  const handleCancel = () => {
    onOpenDialogGetContentChange(false);
    setOpenDialogCancel(false);
    reset();
  };

  return (
    <Dialog
      open={openDialogGetContent}
      TransitionComponent={Transition}
      keepMounted
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          return;
        }
        onOpenDialogGetContentChange(false);
      }}
      disableEscapeKeyDown
      aria-describedby="alert-dialog-slide-description"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '800px',
          },
        },
        overflow: 'visible',
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <DialogTitle>
        <Stepper activeStep={0} alternativeLabel>
          {steps.map((label) => (
            <Step sx={{ mt: 2 }} key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </DialogTitle>

      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
            <Controller
              name="content"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type="text"
                  variant="outlined"
                  multiline
                  rows={4}
                  style={{ width: '100%', marginTop: '3px' }}
                  sx={{
                    '& label': {
                      fontSize: '0.85rem',
                    },
                    '.MuiInputBase-input': {
                      padding: '12px',
                    },
                  }}
                  label={t('placeholder.content')}
                  error={!!fieldState.error}
                  helperText={fieldState.error ? fieldState.error.message : null}
                  InputProps={{
                    endAdornment: (
                      <CustomTooltip title={t('asm.phishing.contentHelper')}>
                        <Box
                          sx={{
                            width: '20px',
                            height: '20px',
                            ml: '5px',
                            alignSelf: 'flex-start',
                            cursor: 'pointer',
                            '&:hover': {
                              color: 'text.secondary',
                            },
                          }}
                        >
                          <IconHelp />
                        </Box>
                      </CustomTooltip>
                    ),
                  }}
                />
              )}
            />
          </Box>
          <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
            <Controller
              name="url"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type="text"
                  variant="outlined"
                  style={{ width: '100%', marginTop: '3px' }}
                  label={t('placeholder.url')}
                  error={!!fieldState.error}
                  helperText={fieldState.error ? fieldState.error.message : null}
                  onKeyDown={handleKeyDown}
                  InputProps={{
                    endAdornment: (
                      <CustomTooltip title={t('asm.phishing.urlHelper')}>
                        <Box
                          sx={{
                            width: '20px',
                            height: '20px',
                            ml: '5px',
                            alignSelf: 'center',
                            cursor: 'pointer',
                            '&:hover': {
                              color: 'text.secondary',
                            },
                          }}
                        >
                          <IconHelp />
                        </Box>
                      </CustomTooltip>
                    ),
                  }}
                />
              )}
            />
          </Box>
          <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
            <Controller
              name="senderName"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type="text"
                  variant="outlined"
                  style={{ width: '100%', marginTop: '3px' }}
                  label={t('placeholder.sender')}
                  error={!!fieldState.error}
                  helperText={fieldState.error ? fieldState.error.message : null}
                  onKeyDown={handleKeyDown}
                />
              )}
            />
          </Box>
        </form>
        <Box sx={{ margin: 'auto', mt: 2, display: 'flex', justifyContent: 'flex-end', mr: 3 }}>
          <PrimaryButton handleClick={handleSubmit(onSubmit)} label={t('action.send')} />
          <CancelButton handleClick={handleCancelClick} />
        </Box>
        <ConfirmCancelModal
          openDialogCancel={openDialogCancel}
          onOpenDialogCancelChange={setOpenDialogCancel}
          handleCancel={handleCancel}
        />
      </DialogContent>
      <CreateCampaign
        openDialogCreateCampaign={openDialogCreateCampaign}
        onOpenDialogCreateCampaignChange={setOpenDialogCreateCampaign}
        handleGetListCampaign={handleGetListCampaign}
        generatedContent={generatedContent}
      />
    </Dialog>
  );
};
export default GetContent;
