import { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Typography, InputBase, Button, useTheme } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { CompanyType, GroupType, UserType } from '@/types/management';
import { decrypt } from '@/utils/crypto';

import CreateCompany from './CreateCompany';
import TableCompany from './TableCompany';

interface CompaniesProps {
  listUserGroups: UserType[][];
  listCompanies: Array<CompanyType>;
  handleGetUser: () => Promise<void>;
  handleGetCompany: () => Promise<void>;
}

const Companies: React.FC<CompaniesProps> = ({ listUserGroups, listCompanies, handleGetUser, handleGetCompany }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));

  const [filterCompany, setFilterCompany] = useState<CompanyType[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [openDialogCreate, setOpenDialogCreate] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);

    const filteredCompanies = listCompanies.filter((company: CompanyType) => {
      const normalizedCompanyName = company?.name_company.toLowerCase();
      return normalizedCompanyName.includes(event.target.value);
    });
    setFilterCompany(filteredCompanies);

    if (event.target.value === '') {
      setFilterCompany([]);
    }
  };

  const groupedCompanies = Object.values(
    listCompanies.reduce((groups: Record<string, GroupType>, company: CompanyType) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { group_name } = company;
      groups[group_name] = groups[group_name] || { group_name, companies: [] };
      groups[group_name].companies.push(company);
      return groups;
    }, {}),
  );

  console.log('groupedCompanies', groupedCompanies);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        <Box
          sx={{
            width: '100%',
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: 1,
          }}
        >
          <InputBase
            sx={{
              width: '100%',
              ml: 1,
              flex: 1,
              '.MuiInputBase-input': {
                padding: '10px 10px',
              },
            }}
            placeholder={t('placeholder.typeSearch')}
            inputProps={{
              'aria-label': 'filter company',
            }}
            endAdornment={
              searchValue && (
                <ClearIcon
                  onClick={() => {
                    setSearchValue('');
                    setFilterCompany([]);
                  }}
                  sx={{
                    mr: 3,
                    color: 'text.secondary',
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                />
              )
            }
            value={searchValue}
            onChange={handleInputChange}
          />
        </Box>
        {inforUser.role === 'super admin' && (
          <Button
            variant="contained"
            fullWidth
            type="button"
            onClick={() => {
              setOpenDialogCreate(true);
            }}
            sx={{
              minWidth: 'fit-content',
              width: 'fit-content',
              ml: 2,
              boxShadow: 'none',
              fontWeight: 600,
              borderRadius: '5px',
              textTransform: 'none',
              backgroundColor: 'action.selected',
              color: '#02bb9f',
              '&:hover': {
                backgroundColor: 'action.hover',
              },
              whiteSpace: 'nowrap',
            }}
          >
            <AddIcon sx={{ mr: 0.5 }} />
            {t('action.addCompany')}
          </Button>
        )}
      </Box>
      <Box sx={{ borderRadius: 2, mt: 3 }}>
        {searchValue ? (
          <>
            {!_.isEmpty(filterCompany) ? (
              <Box sx={{ boxShadow: 5, borderRadius: 2, padding: 2 }}>
                <TableCompany
                  listCompanies={filterCompany}
                  handleGetUser={handleGetUser}
                  handleGetCompany={handleGetCompany}
                />
              </Box>
            ) : (
              <Box sx={{ width: '100%', borderRadius: 2, mt: 3, padding: 2, backgroundColor: 'action.hover' }}>
                <Typography variant="body2" sx={{ textAlign: 'center', color: 'text.secondary' }}>
                  {t('notify.noResult')}
                </Typography>
              </Box>
            )}
          </>
        ) : (
          <>
            {inforUser.role !== 'super admin' ? (
              <Box sx={{ boxShadow: 5, borderRadius: 2, padding: 2 }}>
                <TableCompany
                  listCompanies={listCompanies}
                  handleGetUser={handleGetUser}
                  handleGetCompany={handleGetCompany}
                />
              </Box>
            ) : (
              <>
                {groupedCompanies.map((group: GroupType) => (
                  <Box key={group?.group_name} sx={{ mt: 1 }}>
                    <Accordion sx={{ width: '100%', backgroundColor: 'transparent', boxShadow: 3 }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Typography variant="body2">{group?.group_name}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <TableCompany
                          listCompanies={group?.companies}
                          handleGetUser={handleGetUser}
                          handleGetCompany={handleGetCompany}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                ))}
              </>
            )}
          </>
        )}
      </Box>
      <CreateCompany
        openDialogCreate={openDialogCreate}
        onOpenDialogCreateChange={setOpenDialogCreate}
        listUserGroups={listUserGroups}
        listCompanies={listCompanies}
        handleGetUser={handleGetUser}
        handleGetCompany={handleGetCompany}
      />
    </>
  );
};
export default Companies;
