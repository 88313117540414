import { useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import DownloadIcon from '@mui/icons-material/Download';
import { Box, Button, Grid, LinearProgress, SelectChangeEvent, TextField, Typography, useTheme } from '@mui/material';
import * as _ from 'lodash';
import moment from 'moment-timezone';
import { Helmet } from 'react-helmet';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import ScanButton from '@/components/common/Button/ScanButton';
import StopButton from '@/components/common/Button/StopButton';
import FormSelectDomain from '@/components/common/FormSelectDomain';
import ScanDoneNotice from '@/components/common/Notice/ScanDoneNotice';
import ScanTime from '@/components/common/ScanTime';
import { useCommonInfo } from '@/contexts/Common';
import { useReportInfo } from '@/contexts/Report';
import { useWebSocketContext } from '@/contexts/WebSocketContext';
import useBreakpoints from '@/helpers/useBreakpoints';
import useAsm from '@/Hooks/api/useAsm';
import useNotify from '@/Hooks/common/useNotify';
import useScanError from '@/Hooks/common/useScanError';
import useReport from '@/Hooks/useReport';
import { decrypt } from '@/utils/crypto';
import { LOGO_ESS_COLOR } from '@/utils/imgBase64';

const Report = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { handleInvicti } = useAsm();
  const { isScanError } = useCommonInfo();
  const { onNotify } = useNotify();
  const { report } = useReportInfo();
  const { setReport } = useReport();
  const { setScanError } = useScanError();
  const intervalRef = useRef<any>();
  const { isMobile } = useBreakpoints();

  const domainActive = localStorage.getItem('domainActive')
    ? decrypt(localStorage.getItem('domainActive') as string)
    : '';
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));
  const { dataWebSocket } = useWebSocketContext();

  const [statusScan, setStatusScan] = useState(false);
  const [statusScanWebsocket, setStatusScanWebsocket] = useState(false);
  const [dataInvicti, setDataInvicti] = useState({
    reportData: report?.dataReport,
    isRunning: report?.stateRunning,
    isFirstCall: report?.isFirstCall,
    isSuccessApi: report?.isSuccessApi,
  });
  const [domain, setDomain] = useState(domainActive);
  const [updateTime, setUpdateTime] = useState('');
  const [loading, setLoading] = useState(true);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const filteredList = dataWebSocket.filter((item: any) => item.domain === domain);

  useEffect(() => {
    if (isScanError) {
      setDataInvicti((prevState) => ({
        ...prevState,
        isRunning: false,
        isSuccessApi: false,
      }));
      clearInterval(intervalRef.current);
      clearInterval(report?.intervalId);
      setReport({
        ...report,
        stateRunning: false,
        isSuccessApi: false,
        isFirstCall: false,
      });
      setScanError(false);
    }
  }, [isScanError]);

  useEffect(() => {
    if (report?.intervalId) {
      clearInterval(report?.intervalId);
    }
  }, []);

  useEffect(() => {
    if (dataInvicti.isRunning && !report?.isFinish) {
      if (dataInvicti.isFirstCall) {
        handleGetInvicti();
      }
      if (dataInvicti.isSuccessApi) {
        intervalRef.current = setInterval(() => {
          handleGetInvicti();
        }, 10000);
      } else {
        clearInterval(intervalRef.current);
        handleCacheReport();
      }
    } else {
      clearInterval(intervalRef.current);
      setReport({
        ...report,
        dataReport: dataInvicti.reportData,
        stateRunning: false,
        isSuccessApi: dataInvicti.isSuccessApi,
        isFirstCall: dataInvicti.isFirstCall,
        keySearch: inforUser?.role === 'super admin' ? getValues('searchKey') : domain,
        intervalId: intervalRef.current,
      });
    }
    return () => {
      handleCacheReport();
    };
  }, [dataInvicti.isRunning, dataInvicti.isFirstCall, dataInvicti.isSuccessApi]);

  const handleGetInvicti = async () => {
    const searchKey = inforUser?.role === 'super admin' ? getValues('searchKey') : domain;
    const params = {
      target: searchKey,
    };
    const res = await handleInvicti(params);
    const { data, status } = res;

    setDataInvicti((prevState) => ({
      ...prevState,
      isFirstCall: false,
      isSuccessApi: true,
      reportData: data,
    }));
    if (status === 2) {
      setStatusScan(true);
      setReport({
        ...report,
        dataReport: data,
        stateRunning: false,
        isSuccessApi: false,
        isFirstCall: false,
        isFinish: true,
      });
      clearInterval(intervalRef.current);
      clearInterval(report?.intervalId);
      setDataInvicti((prevState) => ({
        ...prevState,
        isRunning: false,
        isSuccessApi: false,
      }));
      onNotify('success', t('notify.scanReportDone'), null);
      return;
    }
    setReport({
      dataReport: data,
      stateRunning: dataInvicti.isRunning,
      isSuccessApi: dataInvicti.isSuccessApi,
      isFirstCall: dataInvicti.isFirstCall,
      keySearch: inforUser?.role === 'super admin' ? getValues('searchKey') : domain,
      intervalId: intervalRef.current,
      isFinish: false,
    });
  };

  const handleCacheReport = () =>
    setReport({
      ...report,
      dataReport: dataInvicti.reportData,
      stateRunning: dataInvicti.isRunning,
      isSuccessApi: dataInvicti.isSuccessApi,
      isFirstCall: dataInvicti.isFirstCall,
      keySearch: inforUser?.role === 'super admin' ? getValues('searchKey') : domain,
      intervalId: intervalRef.current,
    });

  const convertTime = (time: string) => {
    const outputTime = moment(time).add(15, 'hours').tz('Asia/Bangkok').format('M/D/YYYY h:mm:ss A (Z)');
    return outputTime;
  };

  const validationSchema = yup.object({
    searchKey: yup.string().required(t('validation.fieldRequired')),
  });

  const { control, handleSubmit, getValues } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      searchKey: report?.keySearch,
    },
  });

  const onSubmit = (data: any) => {
    setReport({
      ...report,
      isFinish: false,
    });
    setStatusScan(false);

    setDataInvicti((prevState) => ({
      ...prevState,
      reportData: [],
      isRunning: true,
      isFirstCall: true,
    }));
    setReport(null);
  };

  // useEffect(() => {
  //   if (inforUser?.role !== 'super admin' && !report?.stateRunning) {
  //     setDataInvicti((prevState) => ({
  //       ...prevState,
  //       reportData: [],
  //       isRunning: true,
  //       isFirstCall: true,
  //     }));
  //     setReport(null);
  //   }
  // }, [domain]);

  useEffect(() => {
    if (inforUser?.role !== 'super admin') {
      if (!_.isEmpty(filteredList.find((item: any) => item.title === 'scanInvicti')?.data)) {
        setDataInvicti((prevState) => ({
          ...prevState,
          reportData: filteredList.find((item: any) => item.title === 'scanInvicti')?.data,
        }));
        setUpdateTime(filteredList.find((item: any) => item.title === 'scanInvicti')?.update_time);
        setStatusScanWebsocket(false);
      } else {
        setStatusScanWebsocket(true);
      }
    }
  }, [domain, dataWebSocket]);

  const handleKeyDown = (event: { keyCode: number }) => {
    if (event.keyCode === 13) {
      handleSubmit(onSubmit);
    }
  };

  const handleChangeDomain = (event: SelectChangeEvent) => {
    setDomain(event.target.value);
    setLoading(true);
  };

  useEffect(() => {
    if (!_.isEmpty(dataInvicti.reportData)) {
      // Giải mã dãy Base64 thành dữ liệu nhị phân
      const binaryData = atob(inforUser?.role !== 'super admin' ? dataInvicti.reportData.data : dataInvicti.reportData);

      const uint8Array = new Uint8Array(binaryData.length);
      for (let i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i);
      }

      // Tạo một Blob từ dữ liệu nhị phân
      const blob = new Blob([uint8Array.buffer], { type: 'text/html' });

      // Tạo một URL đối tượng (Object URL) để hiển thị tệp PDF
      const blobUrl: any = URL.createObjectURL(blob);

      if (iframeRef.current && 'src' in iframeRef.current) {
        iframeRef.current.src = blobUrl;

        iframeRef.current.onload = () => {
          if (iframeRef.current) {
            const iframeDocument = iframeRef.current.contentDocument;

            if (iframeDocument) {
              const styleElement = iframeDocument.createElement('style');
              const footerElements = iframeDocument.getElementsByClassName('footer');

              const timeHeaderElements: any = iframeDocument.querySelectorAll('.lh1:not([class*=" "])');
              const timeBodyElements: any = iframeDocument.getElementsByClassName('col-md-8 col-sm-8 col-xs-8 nolrpad');

              for (let i = 0; i < timeHeaderElements.length; i++) {
                const lh1Element = timeHeaderElements[i];

                // Đổi nội dung bên trong class "lh1"
                lh1Element.innerHTML = convertTime(lh1Element?.innerHTML);
              }
              if (timeBodyElements[0]) {
                timeBodyElements[0].innerHTML = convertTime(timeBodyElements[0]?.innerHTML);
              }

              // Thay đổi đường dẫn URL trong quy tắc CSS cho class "img-logo"
              styleElement.innerHTML = `
                    .img-logo {
                      background: url(data:image/png;base64,${LOGO_ESS_COLOR}) no-repeat left center;
                    }
                  `;
              // Thêm style vào head của iframeDocument
              iframeDocument.head.appendChild(styleElement);

              for (let i = 0; i < footerElements.length; i++) {
                const spanElements = footerElements[i].getElementsByTagName('span');
                for (let j = spanElements.length - 1; j >= 0; j--) {
                  const spanElement = spanElements[i];
                  if (spanElement?.parentNode) {
                    spanElement?.parentNode.removeChild(spanElement);
                  }
                }
              }
              setLoading(false);
            }
          }
        };
      }
    }
  }, [dataInvicti.reportData, LOGO_ESS_COLOR]);

  const handleDownload = () => {
    if (iframeRef.current) {
      const iframeDocument = iframeRef.current.contentDocument;
      if (iframeDocument) {
        const htmlContent = iframeDocument.documentElement.outerHTML;
        const blob = new Blob([htmlContent], { type: 'text/html' });
        const downloadUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = 'report.html';
        link.click();

        URL.revokeObjectURL(downloadUrl);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Report - Attack Surface Management</title>
      </Helmet>
      <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.primary' }}>
        {t('asm.report.title')}
      </Typography>
      <Grid sx={{ mt: 2 }}>
        <Box
          sx={{
            backgroundColor: 'background.main',
            flexDirection: 'column',
            padding: 2,
            borderRadius: 1,
            boxShadow: 3,
          }}
        >
          {inforUser?.role === 'super admin' ? (
            <Box sx={{ width: '100%', mt: 2, display: 'flex', flexDirection: 'row' }}>
              <form onSubmit={handleSubmit(onSubmit)} style={{ width: isMobile ? '100%' : '50%' }}>
                <Controller
                  name="searchKey"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      style={{ width: '100%' }}
                      placeholder={t('placeholder.mainDomain')}
                      onKeyDown={handleKeyDown}
                    />
                  )}
                />
              </form>
              {dataInvicti.isRunning ? (
                <StopButton
                  handleClick={() => {
                    setDataInvicti((prevState) => ({
                      ...prevState,
                      isSuccessApi: false,
                      isRunning: false,
                    }));
                  }}
                />
              ) : (
                <ScanButton handleClick={handleSubmit(onSubmit)} />
              )}
            </Box>
          ) : (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <FormSelectDomain domain={domain} handleChangeDomain={handleChangeDomain} />
              {!_.isEmpty(dataInvicti.reportData && updateTime) && <ScanTime updateTime={updateTime} />}
            </Box>
          )}
          {(report?.isFinish || statusScan) && <ScanDoneNotice />}
          {(dataInvicti.isRunning || statusScanWebsocket) && (
            <>
              <Typography
                variant="body2"
                sx={{ marginTop: 3, textAlign: 'center', color: 'error.main', fontWeight: 600 }}
              >
                {t('notify.scanInProgress')}
              </Typography>
              <br />
              <Box sx={{ width: '100%', marginTop: 3 }}>
                <LinearProgress color="info" />
              </Box>
            </>
          )}
          {dataInvicti.reportData && (
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2, textTransform: 'capitalize' }}
                onClick={handleDownload}
                disabled={!iframeRef.current}
              >
                <DownloadIcon sx={{ mr: 1 }} />
                {t('action.download')}
              </Button>
            </Box>
          )}

          {/* {statusSuccess === 2 && ( */}
          <Box sx={{ mt: 4 }}>
            {/* <iframe ref={invicti} title="Base64 to HTML" width="100%" style={{ minHeight: '100vh' }} /> */}
            <iframe
              ref={iframeRef}
              title="Base64 to HTML"
              width="100%"
              style={{ minHeight: '100vh', display: loading ? 'none' : 'block' }}
            />
          </Box>
          {/* )} */}
        </Box>
      </Grid>
    </>
  );
};
export default Report;
