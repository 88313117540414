import * as React from 'react';

import { Link, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import useNotify from '@/Hooks/common/useNotify';

export interface HistoryItem {
  icon: React.ReactNode;
  type: string;
  primaryText: string[];
  title: string;
}

interface CustomizableListProps {
  items: HistoryItem[];
}

export default function CustomizableList({ items }: CustomizableListProps) {
  const { onNotify } = useNotify();
  return (
    <Box sx={{ width: '100%' }}>
      <nav aria-label="main info">
        <List>
          {items.map((item, index) => {
            if (item.type === 'fullname' && item.primaryText?.length === 1) {
              return null;
            }

            const displayedTexts =
              item.type === 'fullname' && item.primaryText?.length > 1 ? item.primaryText.slice(1) : item.primaryText;
            return (
              <ListItem key={index} disablePadding>
                {displayedTexts?.length > 0 && (
                  <Tooltip title={item?.title} arrow placement="left">
                    <ListItemButton
                      onClick={() => {
                        navigator.clipboard.writeText(`${item?.primaryText}`);
                        onNotify('info', 'Đã sao chép vào bộ nhớ đệm', 2000);
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          color: 'text.secondary',
                          '& svg': {
                            fontSize: '1.8em',
                          },
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText className="text-white">
                        {displayedTexts.map((text, index2) => (
                          <React.Fragment key={index2}>
                            {item.type === 'facebook' ? (
                              <Link
                                fontSize={16}
                                href={`https://www.facebook.com/${text}`}
                                target="_blank"
                                rel="noopener"
                              >
                                Facebook
                              </Link>
                            ) : (
                              <Typography
                                variant="body1"
                                style={{ fontSize: '12pt' }}
                                dangerouslySetInnerHTML={{ __html: text.replace(/\\n/g, '<br />') }}
                              />
                            )}
                            {index !== item.primaryText?.length - 1}
                          </React.Fragment>
                        ))}
                        <hr />
                      </ListItemText>
                    </ListItemButton>
                  </Tooltip>
                )}
              </ListItem>
            );
          })}
        </List>
      </nav>
    </Box>
  );
}
