import { ReactElement, useEffect, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, CircularProgress, debounce, InputBase, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import AddTopic from '@/components/Social/topic/AddTopic';
import TableTopic from '@/components/Social/topic/TableTopic';
import useSocial from '@/Hooks/api/useSocial';
import useSetTopicSocial from '@/Hooks/social/useSetTopicSocial';
import { TopicTypes } from '@/types/Social';
import { PAGE_SIZE } from '@/utils/constants';
import { decrypt } from '@/utils/crypto';

const Topic = (): ReactElement => {
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));
  const theme = useTheme();
  const { t } = useTranslation();
  const { setTopicSocial } = useSetTopicSocial();
  const { handleListTopic } = useSocial();
  const [searchParams, setSearchParams] = useSearchParams();

  const [listTopic, setListTopic] = useState<TopicTypes[]>([]);

  const [loadingFilter, setLoadingFilter] = useState(false);
  const [totalTopic, setTotalTopic] = useState(0);
  const [openDialogCreateTopic, setOpenDialogCreateTopic] = useState(false);
  const [keyword, setKeyword] = useState('');

  const searchValue = useMemo(() => searchParams.get('search') || '', [searchParams]);

  useEffect(() => {
    setKeyword(searchValue);
  }, [searchValue]);

  const handleGetListTopic = async () => {
    const params = {
      keyword: searchParams.get('search') || '',
      page: Number(searchParams.get('page') || 1),
      page_size: PAGE_SIZE,
    };

    const res = await handleListTopic(params);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { list_topic, total } = res;
    setListTopic(list_topic);
    setTotalTopic(total);
    setTopicSocial(list_topic[0]);
    setLoadingFilter(false);
  };

  useEffect(() => {
    handleGetListTopic();
  }, [searchParams]);

  const debouncedSearch = useMemo(
    () =>
      debounce((searchTerm) => {
        const trimmedSearchTerm = searchTerm.trim();
        const newParams = new URLSearchParams(searchParams);
        if (trimmedSearchTerm !== '' && trimmedSearchTerm.length > 2) {
          newParams.set('search', trimmedSearchTerm);
          newParams.set('page', '1');
        } else {
          newParams.delete('search');
        }
        setSearchParams(newParams);
        setLoadingFilter(false);
      }, 2000),
    [searchParams, setSearchParams],
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value;
    setKeyword(searchTerm);
    const trimmedSearchTerm = searchTerm.trim();
    if (trimmedSearchTerm.length > 2 || trimmedSearchTerm === '') {
      setLoadingFilter(true);
      debouncedSearch(searchTerm);
    }
  };

  const clearSearch = () => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set('page', '1');
    newParams.delete('search');
    setSearchParams(newParams);
    setKeyword('');
  };

  return (
    <>
      <Typography variant="h6" sx={{ fontWeight: 600 }}>
        {t('smcc.titleTopic')}
      </Typography>
      <Box sx={{ mt: { xs: 1, md: 3 } }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: 2,
            marginBottom: inforUser.role === 'super admin' ? '40px' : 0,
          }}
        >
          <Box
            sx={{
              width: '100%',
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: 1,
            }}
          >
            <InputBase
              sx={{
                width: '100%',
                ml: 1,
                flex: 1,
                '.MuiInputBase-input': {
                  padding: '12px 12px',
                },
              }}
              placeholder={t('placeholder.typeSearch')}
              inputProps={{
                // Thêm inputProps
                'aria-label': 'filter company',
              }}
              endAdornment={
                <>
                  {loadingFilter && <CircularProgress color="inherit" size={20} sx={{ mr: 3 }} />}
                  {searchValue && (
                    <ClearIcon
                      onClick={clearSearch}
                      sx={{
                        mr: 3,
                        color: 'text.disabled',
                        '&:hover': {
                          cursor: 'pointer',
                        },
                      }}
                    />
                  )}
                </>
              }
              value={keyword}
              onChange={handleInputChange}
            />
          </Box>
          {inforUser.role !== 'super admin' && (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="button"
              onClick={() => {
                setOpenDialogCreateTopic(true);
              }}
              sx={{
                width: 'fit-content',
                ml: 2,
                borderRadius: '5px',
                textTransform: 'none',
                backgroundColor: 'action.selected',
                color: '#02bb9f',
                '&:hover': {
                  backgroundColor: 'action.hover',
                },
                whiteSpace: 'nowrap',
              }}
            >
              <AddIcon sx={{ mr: 0.5 }} />
              {t('action.addTopic')}
            </Button>
          )}
        </Box>
        <TableTopic
          listTopics={listTopic}
          handleGetListTopic={handleGetListTopic}
          totalTopic={totalTopic}
          loadingFilter={loadingFilter}
        />
      </Box>
      <AddTopic
        openDialogCreate={openDialogCreateTopic}
        onOpenDialogCreateChange={setOpenDialogCreateTopic}
        handleGetListTopic={handleGetListTopic}
      />
    </>
  );
};
export default Topic;
