import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography, TextField, Dialog, DialogTitle, DialogContent, Autocomplete, Chip } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import CancelButton from '@/components/common/Button/CancelButton';
import PrimaryButton from '@/components/common/Button/PrimaryButton';
import { Transition } from '@/components/common/Transition';
import ConfirmCancelModal from '@/components/Modal/ConfirmCancelModal';
import useAuth from '@/Hooks/api/useAuth';
import { decrypt } from '@/utils/crypto';

interface CreateUserProps {
  openDialogCreate: boolean;
  onOpenDialogCreateChange: (newValue: boolean) => void;
  handleGetUser: () => Promise<void>;
  handleGetCompany: () => Promise<void>;
}

const CreateUser: React.FC<CreateUserProps> = ({
  openDialogCreate,
  onOpenDialogCreateChange,
  handleGetUser,
  handleGetCompany,
}) => {
  const { t } = useTranslation();
  const { handleCreateUser, handleCheckUserExist } = useAuth();
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));

  const [valueRole, setValueRole] = useState(inforUser.role === 'super admin' ? 'manager' : 'member');
  const [openDialogCancel, setOpenDialogCancel] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleChangeRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueRole((event.target as HTMLInputElement).value);
  };

  const validationSchema = yup.object().shape({
    email: yup
      .array()
      .of(yup.string().required(t('validation.fieldRequired')).email(t('validation.invalidEmail')))
      .min(1, t('validation.fieldRequired')),
  });

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    reset,
    getValues,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: [],
    },
  });

  const navigateSuccess = () => {
    reset();
    onOpenDialogCreateChange(false);
    handleGetUser();
    handleGetCompany();
  };

  const onSubmit = (data: any) => {
    const listParams = data.email.map((email: string) => ({
      username: email.trim(),
      email: email.trim(),
      expire_date_license: inforUser.expireDate,
      permission: valueRole,
      group_name: '',
      list_company: [],
    }));
    handleCreateUser(listParams, navigateSuccess);
  };

  const navigateSuccessCheckUser = () => {
    const currentEmails = getValues('email') || [];
    setValue('email', [...currentEmails, inputValue], { shouldValidate: true });
    setInputValue('');
  };

  const navigateFailCheckUser = (errorMessage: string) => {
    setError('email', {
      type: 'manual',
      message: errorMessage || 'Email đã tồn tại',
    });
  };

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputValue.trim()) {
      event.preventDefault();
      event.stopPropagation();

      const currentEmails = getValues('email') || [];
      const emailRegex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;

      if (!emailRegex.test(inputValue.trim())) {
        setError('email', {
          type: 'manual',
          message: t('validation.invalidEmail'),
        });
        return;
      }

      if (currentEmails.includes(inputValue.trim())) {
        setError('email', {
          type: 'manual',
          message: 'Email đã tồn tại',
        });
        return;
      }
      handleCheckUserExist({ username: inputValue }, navigateSuccessCheckUser, navigateFailCheckUser);
    }
  };

  const handleCancelClick = () => {
    if (isDirty || inputValue !== '' || getValues('email')?.length !== 0) {
      setOpenDialogCancel(true);
    } else {
      onOpenDialogCreateChange(false);
      reset();
    }
  };

  const handleCancel = () => {
    onOpenDialogCreateChange(false);
    setOpenDialogCancel(false);
    reset();
    setInputValue('');
  };

  return (
    <>
      <Dialog
        open={openDialogCreate}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
          onOpenDialogCreateChange(false);
        }}
        disableEscapeKeyDown
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '800px',
            },
          },
          overflow: 'visible',
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <DialogTitle>
          <Typography sx={{ fontSize: '2rem', marginTop: 1 }}>{t('action.addUser')}</Typography>
        </DialogTitle>

        <DialogContent>
          <Box sx={{ borderRadius: 2, mt: 1, padding: 2, backgroundColor: 'action.hover' }}>
            <Box sx={{ width: '100%', marginBottom: 2 }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue={[]}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      id="tags-filled"
                      options={[]}
                      freeSolo
                      value={field.value || []}
                      onChange={(_, value, reason) => {
                        field.onChange(value);
                        if (reason === 'clear') {
                          clearErrors('email');
                        }
                      }}
                      getOptionLabel={(option) => option}
                      renderTags={
                        (tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip variant="outlined" label={option} {...getTagProps({ index })} key={option} />
                          ))
                        // eslint-disable-next-line react/jsx-curly-newline
                      }
                      sx={{
                        mt: 2,
                        '.MuiChip-label': {
                          fontSize: '1.4rem',
                        },
                      }}
                      inputValue={inputValue}
                      onInputChange={(_, newInputValue, reason) => {
                        setInputValue(newInputValue);
                        if ((reason === 'clear' || newInputValue === '') && typeof errors.email?.message === 'string') {
                          clearErrors('email');
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={t('placeholder.email')}
                          onKeyDown={handleKeyDown}
                          error={!!errors.email}
                          helperText={errors.email?.message || errors.email?.[0]?.message}
                        />
                      )}
                    />
                  )}
                />

                <Typography sx={{ fontSize: '1.2rem', color: 'text.disabled', mt: 1 }}>
                  {t('smcc.enterToAdd')}
                </Typography>

                <FormControl
                  sx={{
                    mt: 4,
                    marginRight: '20px',
                    '.MuiFormLabel-root': {
                      fontSize: '1.2rem',
                    },
                  }}
                >
                  <FormLabel id="demo-controlled-radio-buttons-group">{t('role.role')}</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={valueRole}
                    onChange={handleChangeRole}
                  >
                    {(inforUser?.role === 'manager' || inforUser?.role === 'admin') && (
                      <FormControlLabel
                        value="member"
                        control={<Radio />}
                        label={t('role.member')}
                        sx={{
                          '.MuiTypography-root': {
                            fontSize: '1.4rem',
                          },
                        }}
                      />
                    )}
                    {inforUser?.role === 'super admin' && (
                      <FormControlLabel
                        value="manager"
                        control={<Radio />}
                        label={t('role.manager')}
                        sx={{
                          '.MuiTypography-root': {
                            fontSize: '1.4rem',
                          },
                        }}
                      />
                    )}
                  </RadioGroup>
                </FormControl>
              </form>
            </Box>
            <Box sx={{ margin: 'auto', mt: 2, display: 'flex', justifyContent: 'flex-end', mr: 3 }}>
              <PrimaryButton handleClick={handleSubmit(onSubmit)} label={t('action.add')} />
              <CancelButton handleClick={handleCancelClick} />
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <ConfirmCancelModal
        openDialogCancel={openDialogCancel}
        onOpenDialogCancelChange={setOpenDialogCancel}
        handleCancel={handleCancel}
      />
    </>
  );
};
export default CreateUser;
