/* eslint-disable no-underscore-dangle */
import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Autocomplete,
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

import CancelButton from '@/components/common/Button/CancelButton';
import PrimaryButton from '@/components/common/Button/PrimaryButton';
import { Transition } from '@/components/common/Transition';
import ConfirmCancelModal from '@/components/Modal/ConfirmCancelModal';
import useSocialApi from '@/Hooks/api/useSocial';
import { decrypt } from '@/utils/crypto';

interface AddTopicProps {
  openDialogCreate: boolean;
  onOpenDialogCreateChange: (newValue: boolean) => void;
  handleGetListTopic: () => void;
}

const AddTopic: React.FC<AddTopicProps> = ({ openDialogCreate, onOpenDialogCreateChange, handleGetListTopic }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { handleCreateTopic } = useSocialApi();
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));
  const [searchParams] = useSearchParams();

  const [inputValue, setInputValue] = useState('');
  const [openDialogCancel, setOpenDialogCancel] = useState(false);

  const validationSchema = yup.object().shape({
    topicName: yup.string().required(t('validation.fieldRequired')),
    keywords: yup.array().min(1, t('validation.fieldRequired')),
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      topicName: '',
      keywords: [],
    },
  });

  const createSuccess = () => {
    onOpenDialogCreateChange(false);
    reset();
    if (Array.from(searchParams.keys()).length === 1 && searchParams.get('page') === '1') {
      handleGetListTopic();
    } else {
      const newParams = new URLSearchParams();
      newParams.set('page', '1');
      navigate({ search: newParams.toString() });
    }
  };

  const onSubmit = async (data: any) => {
    const params = {
      topic_name: data.topicName,
      key_word_main: data.keywords,
      group_id: inforUser.groupId,
    };
    await handleCreateTopic(params, createSuccess);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputValue) {
      event.preventDefault();
      setValue('keywords', [...(getValues('keywords') || []), inputValue], { shouldValidate: true });
      setInputValue('');
    }
  };

  const handleCancelClick = () => {
    if (isDirty) {
      setOpenDialogCancel(true);
    } else {
      onOpenDialogCreateChange(false);
    }
  };

  const handleCancel = () => {
    onOpenDialogCreateChange(false);
    setOpenDialogCancel(false);
    reset();
  };

  return (
    <Dialog
      open={openDialogCreate}
      TransitionComponent={Transition}
      keepMounted
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          return;
        }
        onOpenDialogCreateChange(false);
      }}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '500px',
          },
        },
        overflow: 'visible',
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0,0,0,0.2)',
        },
      }}
    >
      <DialogTitle>
        <Typography sx={{ fontSize: '2rem', marginTop: 1 }}>{t('action.addTopic')}</Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ border: `1px solid ${theme.palette.divider}`, borderRadius: 1, padding: 2 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="topicName"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type="text"
                  style={{ width: '100%' }}
                  sx={{
                    mt: 1,
                  }}
                  label={t('placeholder.topic')}
                  error={!!fieldState.error}
                  helperText={fieldState.error ? fieldState.error.message : null}
                  id="input-with-icon-textfield"
                  variant="outlined"
                />
              )}
            />
            <Controller
              name="keywords"
              control={control}
              defaultValue={[]}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  multiple
                  id="tags-filled"
                  options={[]}
                  freeSolo
                  value={field.value || []}
                  onChange={(_, value) => field.onChange(value)}
                  getOptionLabel={(option) => option}
                  renderTags={
                    (tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} key={option} />
                      ))
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                  sx={{
                    mt: 3,
                    '.MuiChip-label': {
                      fontSize: '1.4rem',
                    },
                  }}
                  inputValue={inputValue}
                  onInputChange={(_, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={t('placeholder.keyword')}
                      onKeyDown={handleKeyDown}
                      error={!!errors.keywords}
                      helperText={errors.keywords?.message}
                    />
                  )}
                />
              )}
            />

            <Typography sx={{ fontSize: '1.2rem', color: 'text.disabled', mt: 1 }}>{t('smcc.enterToAdd')}</Typography>
          </form>
          <Box sx={{ margin: 'auto', mt: 2, display: 'flex', justifyContent: 'flex-end', mr: 3 }}>
            <PrimaryButton handleClick={handleSubmit(onSubmit)} label={t('action.create')} />
            <CancelButton handleClick={handleCancelClick} />
          </Box>
          <ConfirmCancelModal
            openDialogCancel={openDialogCancel}
            onOpenDialogCancelChange={setOpenDialogCancel}
            handleCancel={handleCancel}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default AddTopic;
