import { useTranslation } from 'react-i18next';

import { httpStatus } from '@/configs/Enums/httpStatus';
import useAsmService from '@/services/asm';

import useLoading from '../common/useLoading';
import useLoadingGif from '../common/useLoadingGif';
import useNotify from '../common/useNotify';

const useAsm = () => {
  const {
    getScanSubdomain,
    getSearchSubdomain,
    getSearchDns,
    getSearchInfo,
    getSearchCidr,
    getSearchPort,
    getSearchSsl,
    getScanVul,
    getSiteMap,
    getStatistics,
    getFilterInfoSubdomain,
    getListCVE,
    getListCWE,
    getListVendors,
    getAnalysisCVE,
    getListNews,
    getDetailNews,
    getFiterNews,
    getTopNews,
    getInvicti,
    getScoreSystem,
    getListAsset,
    createAsset,
    editAsset,
    deleteAsset,
    getListCVEAsset,
    getNiktoScan,
  } = useAsmService();
  const { setLoadingGif } = useLoadingGif();
  const { setLoading } = useLoading();
  const { t } = useTranslation();
  const { onNotify } = useNotify();

  const handleSearchSubdomain = async (params: {}) => {
    const res: any = await getSearchSubdomain(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      return data;
    }
  };

  const handleScanSubdomain = async (params: {}) => {
    const res: any = await getScanSubdomain(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      return data;
    }
  };

  const handleSearchDns = async (params: {}) => {
    const res: any = await getSearchDns(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      return data;
    }
  };

  const handleSearchInfo = async (params: {}) => {
    const res: any = await getSearchInfo(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      return data;
    }
  };
  const handleSearchSsl = async (params: {}) => {
    const res: any = await getSearchSsl(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      return data;
    }
  };
  const handleSearchCidr = async (params: {}) => {
    setLoadingGif(true);
    const res: any = await getSearchCidr(params);
    setLoadingGif(false);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      return data;
    }
  };
  const handleSearchPort = async (params: {}) => {
    setLoadingGif(true);
    const res: any = await getSearchPort(params);
    setLoadingGif(false);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      return data;
    }
  };

  const handleScanVul = async (params: {}) => {
    const res: any = await getScanVul(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      return data;
    }
  };

  const handleSiteMap = async (params: {}) => {
    const res: any = await getSiteMap(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      return data;
    }
  };

  const handleStatistics = async (params: {}) => {
    const res: any = await getStatistics(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      return data;
    }
  };

  const handleFilterInfoSubdomain = async (params: {}) => {
    const res: any = await getFilterInfoSubdomain(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      return data;
    }
  };
  const handleListCVE = async (params: {}) => {
    const res: any = await getListCVE(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      return data;
    }
  };

  const handleListCWE = async (params: {}) => {
    const res: any = await getListCWE(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      return data;
    }
  };

  const handleListVendors = async (params: {}) => {
    const res: any = await getListVendors(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      return data;
    }
  };

  const handleAnalysisCVE = async (params: {}, navigateSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await getAnalysisCVE(params);
    setLoadingGif(false);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      navigateSuccess();
      return data;
    }
  };

  const handleListNews = async (params: {}) => {
    const res: any = await getListNews(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      return data;
    }
  };

  const handleDetailNews = async (params: {}) => {
    const res: any = await getDetailNews(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      return data;
    }
  };

  const handleFilterNews = async (params: {}) => {
    const res: any = await getFiterNews(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      return data;
    }
  };

  const handleTopNews = async (params: {}) => {
    const res: any = await getTopNews(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      return data;
    }
  };

  const handleInvicti = async (params: {}) => {
    const res: any = await getInvicti(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      return data;
    }
  };

  const handleScoreSystem = async (params: {}) => {
    const res: any = await getScoreSystem(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      return data;
    }
  };

  // Assets

  const handleGetListAsset = async (params: {}) => {
    setLoadingGif(true);
    setLoading(true);
    const res: any = await getListAsset(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      setLoadingGif(false);
      setLoading(false);
      return data;
    }
  };

  const handleCreateAsset = async (body: any, navigateCreateSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await createAsset(body);
    const { status } = res || {};
    if (status === httpStatus.StatusOK) {
      onNotify('success', t('notify.createAsset'), 3000);
      navigateCreateSuccess();
      setLoadingGif(false);
    }
  };

  const handleEditAsset = async (body: any, navigateEditSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await editAsset(body);
    const { status } = res || {};
    if (status === httpStatus.StatusOK) {
      onNotify('success', t('notify.editAsset'), 3000);
      navigateEditSuccess();
      setLoadingGif(false);
    }
  };

  const handleDeleteAsset = async (body: any, navigateDeleteSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await deleteAsset(body);
    const { status } = res || {};
    if (status === httpStatus.StatusOK) {
      onNotify('success', t('notify.deleteAsset'), 3000);
      navigateDeleteSuccess();
      setLoadingGif(false);
    }
  };

  const handleGetListCVE = async (params: {}) => {
    const res: any = await getListCVEAsset(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      return data;
    }
  };

  const handleNiktoScan = async (body: {}) => {
    setLoadingGif(true);
    const res: any = await getNiktoScan(body);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      setLoadingGif(false);
      return data;
    }
    if (status === httpStatus.StatusAccepted) {
      onNotify('info', data?.message, 3000);
      setLoadingGif(false);
    }
  };

  return {
    handleScanSubdomain,
    handleSearchSubdomain,
    handleSearchDns,
    handleSearchInfo,
    handleSearchCidr,
    handleSearchPort,
    handleSearchSsl,
    handleScanVul,
    handleSiteMap,
    handleStatistics,
    handleFilterInfoSubdomain,
    handleListCVE,
    handleListCWE,
    handleListVendors,
    handleAnalysisCVE,
    handleListNews,
    handleTopNews,
    handleInvicti,
    handleDetailNews,
    handleFilterNews,
    handleScoreSystem,
    handleGetListAsset,
    handleCreateAsset,
    handleEditAsset,
    handleDeleteAsset,
    handleGetListCVE,
    handleNiktoScan,
  };
};

export default useAsm;
