import { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { Box, Button, Typography } from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import AddMonitor from '@/components/Management/keywordMonitor/AddMonitor';
import TableMonitor from '@/components/Management/keywordMonitor/TableMonitor';
import useManagenent from '@/Hooks/api/useManagenent';
import { MonitorType } from '@/types/management';
import { PAGE_SIZE } from '@/utils/constants';

const KeywordMonitorManagement = () => {
  const { t } = useTranslation();
  const { handleGetListMonitor, handleTriggerSendMail } = useManagenent();
  const [searchParams, setSearchParams] = useSearchParams();

  const [listMonitors, setListMonitors] = useState<MonitorType[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [openDialogCreate, setOpenDialogCreate] = useState(false);

  const handleGetList = async () => {
    const params = {
      page: Number(searchParams.get('page') || 1),
      page_size: PAGE_SIZE,
    };
    const res = await handleGetListMonitor(params);
    const { results, count } = res;
    setListMonitors(results);
    setTotalItems(count);
  };

  useEffect(() => {
    handleGetList();
  }, [searchParams.get('page')]);

  return (
    <>
      <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.primary' }}>
        {t('management.keywordMonitor.title')}
      </Typography>

      <Box sx={{ mt: { xs: 1, md: 3 } }}>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          {!_.isEmpty(listMonitors) && (
            <Button
              variant="contained"
              fullWidth
              type="button"
              onClick={() => {
                handleTriggerSendMail({});
              }}
              sx={{
                width: '180px',
                ml: 2,
                boxShadow: 'none',
                fontWeight: 600,
                borderRadius: '5px',
                textTransform: 'none',

                backgroundColor: 'action.selected',
                color: '#bd5d0f',
                '&:hover': {
                  backgroundColor: 'action.hover',
                },
                whiteSpace: 'nowrap',
              }}
            >
              <RocketLaunchIcon sx={{ mr: 1 }} />
              {t('management.keywordMonitor.monitor')}
            </Button>
          )}
          <Button
            variant="contained"
            fullWidth
            type="button"
            onClick={() => {
              setOpenDialogCreate(true);
            }}
            sx={{
              width: '180px',
              ml: 4,
              boxShadow: 'none',
              fontWeight: 600,
              borderRadius: '5px',
              textTransform: 'none',

              backgroundColor: 'action.selected',
              color: '#02bb9f',
              '&:hover': {
                backgroundColor: 'action.hover',
              },
              whiteSpace: 'nowrap',
            }}
          >
            <AddIcon sx={{ mr: 0.5 }} />
            {t('management.keywordMonitor.addMonitor')}
          </Button>
        </Box>
        <Box>
          <TableMonitor listMonitors={listMonitors} totalItems={totalItems} handleGetList={handleGetList} />
        </Box>
      </Box>
      <AddMonitor
        openDialogCreate={openDialogCreate}
        onDialogCreateChange={setOpenDialogCreate}
        handleGetList={handleGetList}
      />
    </>
  );
};
export default KeywordMonitorManagement;
