import { useState, useEffect, useRef } from 'react';

import { CardMedia, Skeleton } from '@mui/material';

const LazyImage = ({ src, alt }: { src: string; alt: string }) => {
  const [isVisible, setIsVisible] = useState(false);
  const imgRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 },
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <div ref={imgRef} style={{ minHeight: 200 }}>
      {!isVisible ? (
        <Skeleton variant="rectangular" width="100%" height={200} />
      ) : (
        <CardMedia component="img" alt={alt} src={src} sx={{ height: '200px' }} loading="lazy" />
      )}
    </div>
  );
};

export default LazyImage;
