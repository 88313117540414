import { useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import { TableContainer, TableHead, TableRow, TableCell, TableBody, Table, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ConfirmDeleteModal from '@/components/Modal/ConfirmDeleteModal';
import useAuth from '@/Hooks/api/useAuth';
import { CompanyType } from '@/types/management';
import { decrypt } from '@/utils/crypto';

interface TableCompanyProps {
  listCompanies: Array<CompanyType>;
  handleGetUser: () => Promise<void>;
  handleGetCompany: () => Promise<void>;
}

const TableCompany: React.FC<TableCompanyProps> = ({ listCompanies, handleGetUser, handleGetCompany }) => {
  const { t } = useTranslation();
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));
  const { handleEditCompany } = useAuth();

  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [paramsDelete, setParamsDelete] = useState({
    nameCompany: '',
    nameClient: '',
    mainDomain: '',
    address: '',
    country: '',
    groupName: '',
  });

  const navigateSuccess = () => {
    handleGetUser();
    handleGetCompany();
    setOpenDialogDelete(false);
  };

  const handleDeleteCompany = () => {
    const params = {
      info_company: {
        name_company: paramsDelete.nameCompany,
        name_client: paramsDelete.nameClient,
        main_domain: paramsDelete.mainDomain,
        country: paramsDelete.country,
        address: paramsDelete.address,
      },
      group_name: paramsDelete.groupName,
    };
    const body = {
      params,
      type: 'delete',
    };
    handleEditCompany(body, navigateSuccess);
  };

  return (
    <>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t('table.company.company')}</TableCell>
              <TableCell>{t('table.company.client')}</TableCell>
              <TableCell>{t('table.company.domain')}</TableCell>
              <TableCell>{t('table.company.address')}</TableCell>
              <TableCell>{t('table.company.country')}</TableCell>
              <TableCell>{t('table.company.group')}</TableCell>
              {inforUser.role === 'super admin' && <TableCell> </TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {listCompanies.map((row: CompanyType) => (
              <>
                <TableRow
                  key={row.main_domain}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell>{row?.name_company}</TableCell>
                  <TableCell>{row?.name_client}</TableCell>
                  <TableCell>{row?.main_domain}</TableCell>
                  <TableCell>{row?.address}</TableCell>
                  <TableCell>{row?.country}</TableCell>
                  <TableCell>{row?.group_name}</TableCell>
                  {inforUser.role === 'super admin' && (
                    <TableCell>
                      <Tooltip title={t('tooltip.delete')} placement="right">
                        <DeleteIcon
                          onClick={() => {
                            setOpenDialogDelete(true);
                            setParamsDelete({
                              nameCompany: row?.name_company,
                              nameClient: row?.name_client,
                              mainDomain: row?.main_domain,
                              address: row?.address,
                              country: row?.country,
                              groupName: row?.group_name,
                            });
                          }}
                          sx={{
                            cursor: 'pointer',
                            '&:hover': {
                              color: 'error.main',
                            },
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                  )}
                </TableRow>
                <ConfirmDeleteModal
                  openDialogDelete={openDialogDelete}
                  onOpenDialogDeleteChange={setOpenDialogDelete}
                  handleDelete={handleDeleteCompany}
                />
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default TableCompany;
