import React from 'react';

import { Button } from '@mui/material';

interface Props {
  handleClick: () => void;
  label: string;
  disable?: boolean;
}
const PrimaryButton: React.FC<Props> = ({ handleClick, disable, label }) => {
  return (
    <Button
      variant="contained"
      fullWidth
      type="submit"
      onClick={handleClick}
      disabled={disable}
      sx={{
        width: '90px',
        fontWeight: '500',
        borderRadius: '5px',
        textTransform: 'none',
        transition: 'all .1s ease-in-out',
        '&:active': {
          transform: 'translate(0, calc(-5 * 1%)) scale(0.98)',
          transition: 'all .1s ease-in-out',
        },
        whiteSpace: 'nowrap',
        alignSelf: 'center',
      }}
    >
      {label}
    </Button>
  );
};
export default PrimaryButton;
