import { ReactElement, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import StyledTab from '@/components/Tab/StyledTab';
import StyledTabs from '@/components/Tab/StyledTabs';

interface PhishingProps {
  children?: React.ReactNode;
}

const Phishing: React.FC<PhishingProps> = ({ children }): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    switch (newValue) {
      case 0:
        navigate('/attack/campaign');
        break;
      case 1:
        navigate('/attack/malware');
        break;
      default:
        navigate('/attack/campaign');
        break;
    }
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.primary' }}>
        {t('asm.phishing.title')}
      </Typography>
      <Box sx={{ mt: 1 }}>
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <StyledTabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              <StyledTab label={t('asm.phishing.campaign')} />
              {/* <StyledTab label={t('asm.phishing.malware')} /> */}
            </StyledTabs>
          </Box>
          <Box>{children}</Box>
        </Box>
      </Box>
    </Box>
  );
};
export default Phishing;
