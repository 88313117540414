import React, { useEffect, useState } from 'react';

import { Box, Typography, useTheme, Pagination, Grid } from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import DarkwebDetail from '@/components/Dataleak/darkweb/DarkwebDetail';
import TitlePostsDarkweb from '@/components/Dataleak/darkweb/TitlePostsDarkweb';
import Download from '@/components/Dataleak/Download';
import Keyword from '@/components/Dataleak/Keyword';
import StyledTab from '@/components/Tab/StyledTab';
import StyledTabs from '@/components/Tab/StyledTabs';
import useDataleak from '@/Hooks/api/useDataleak';
import { DarkwebTypes, KeywordTypes } from '@/types/dataleak';
import { PAGE_SIZE } from '@/utils/constants';

const Darkweb = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { handleListDarkweb } = useDataleak();
  const [searchParams, setSearchParams] = useSearchParams();

  const [listKeywords, setListKeywords] = useState<KeywordTypes[]>([]);
  const [listDarkweb, setListDarkweb] = useState<DarkwebTypes[]>([]);
  const [totalDarkweb, setTotalDarkweb] = useState(0);
  const [openDialogDetail, setOpenDialogDetail] = useState(false);
  const [detailSelected, setDetailSelected] = useState<DarkwebTypes>();

  const navigate = useNavigate();
  const location = useLocation();

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate('/dataleak');
        break;
      case 1:
        navigate('/darkweb');
        break;
      default:
        navigate('/dataleak');
        break;
    }
  };

  const path = location.pathname;
  useEffect(() => {
    switch (path) {
      case '/dataleak':
        setValue(0);
        break;
      case '/darkweb':
        setValue(1);
        break;
      default:
        setValue(0);
        break;
    }
  }, [path]);

  const handleGetListDarkweb = async () => {
    const params = {
      page: Number(searchParams.get('page') || 1),
      page_size: PAGE_SIZE,
    };
    const res = await handleListDarkweb(params);
    setListDarkweb(res.list_data);
    setListKeywords(res.list_key_word);
    setTotalDarkweb(res.total);
  };

  useEffect(() => {
    handleGetListDarkweb();
  }, [searchParams.get('page')]);

  const handleChangePage = (pageValue: number) => {
    setSearchParams({ page: pageValue.toString() });
  };

  return (
    <>
      <Box sx={{ mt: { xs: 1, md: 3 } }}>
        <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.primary' }}>
          Dataleak - Darkweb
        </Typography>
        <Keyword
          listKeywords={listKeywords}
          setListKeywords={setListKeywords}
          handleGetListKeywords={handleGetListDarkweb}
        />

        <Box sx={{ mt: 1 }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between' }}>
            <StyledTabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              <StyledTab label="Dataleak" />
              <StyledTab label="Darkweb" />
            </StyledTabs>
            <Download listData={listDarkweb} />
          </Box>
          <Box sx={{ mt: 3 }}>
            {!_.isEmpty(listDarkweb) ? (
              <>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  {listDarkweb.map((item: DarkwebTypes, index: number) => (
                    <Grid key={item.id} item xs={12} md={6}>
                      <Box
                        sx={{
                          border: `1px solid ${theme.palette.divider}`,
                          borderRadius: 1,
                          padding: 2,
                          minHeight: '270px',
                        }}
                      >
                        <TitlePostsDarkweb item={item} />

                        <Typography
                          onClick={() => {
                            setOpenDialogDetail(true);
                            setDetailSelected(item);
                          }}
                          sx={{
                            mt: 2,
                            cursor: 'pointer',
                            letterSpacing: '0.01em',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            WebkitLineClamp: 2,
                            textOverflow: 'ellipsis',
                            fontWeight: 600,
                            color: 'text.primary',
                          }}
                        >
                          {item?.thread}
                        </Typography>

                        <Grid container spacing={2} direction="row" sx={{ justifyContent: 'space-between' }}>
                          <Grid item xs={8} sm={9}>
                            <Typography
                              variant="body2"
                              onClick={() => {
                                setOpenDialogDetail(true);
                                setDetailSelected(item);
                              }}
                              sx={{
                                mt: 1,
                                cursor: 'pointer',
                                letterSpacing: '0.01em',
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                                WebkitLineClamp: 5,
                                textOverflow: 'ellipsis',
                                color: 'text.secondary',
                              }}
                            >
                              {item?.post_text}
                            </Typography>
                            <Typography
                              variant="caption"
                              onClick={() => {
                                setOpenDialogDetail(true);
                                setDetailSelected(item);
                              }}
                              sx={{
                                mt: 0.5,
                                width: 'fit-content',
                                cursor: 'pointer',
                                color: 'text.secondary',
                                ':hover': {
                                  color: 'primary.main',
                                  textDecoration: 'underline',
                                },
                                fontStyle: 'italic',
                              }}
                            >
                              {t('action.showMore')}
                            </Typography>
                          </Grid>
                          <Grid item xs={4} sm={3}>
                            {item?.post_screenshot && (
                              <Box
                                component="img"
                                src={item?.post_screenshot}
                                onClick={() => {
                                  setOpenDialogDetail(true);
                                  setDetailSelected(item);
                                }}
                                sx={{
                                  height: '100px',
                                  width: '100%',
                                  borderRadius: 1,
                                  objectFit: 'cover',
                                  cursor: 'pointer',
                                }}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  ))}
                </Grid>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 3 }}>
                  <Pagination
                    count={Math.ceil(totalDarkweb / PAGE_SIZE)}
                    color="primary"
                    onChange={(event, valuePage) => {
                      handleChangePage(valuePage);
                    }}
                    page={Number(searchParams.get('page') || 1)}
                  />
                </Box>
              </>
            ) : (
              <Typography variant="body2" sx={{ paddingY: 2, textAlign: 'center', color: 'text.secondary' }}>
                {t('notify.noResult')}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      <DarkwebDetail
        openDialogDetail={openDialogDetail}
        onDialogDetailChange={setOpenDialogDetail}
        detailSelected={detailSelected ?? ({} as DarkwebTypes)}
      />
    </>
  );
};
export default Darkweb;
