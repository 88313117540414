import React, { ReactNode } from 'react';

import { Box } from '@mui/material';
import { Helmet } from 'react-helmet';

import Header from './Header';

interface PhishingLayoutProps {
  children: ReactNode;
}

const PhishingLayout: React.FC<PhishingLayoutProps> = ({ children }) => {
  return (
    <>
      <Helmet>
        <title>Attack deployment - Attack Surface Management</title>
      </Helmet>
      <Header label="" />
      <Box
        sx={{
          padding: '60px 20px',
          minHeight: '100vh',
          backgroundColor: 'background.dark',
        }}
      >
        <Box sx={{ mt: 3 }}>{children}</Box>
      </Box>
    </>
  );
};

export default PhishingLayout;
