import { useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Typography, Dialog, DialogTitle, DialogContent, Box, TextField, Button, Autocomplete } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

import { Transition } from '@/components/common/Transition';
import useManagenent from '@/Hooks/api/useManagenent';

interface AddMonitorProps {
  openDialogCreate: boolean;
  onDialogCreateChange: (newValue: boolean) => void;
  handleGetList: () => Promise<void>;
}

const AddMonitor: React.FC<AddMonitorProps> = ({ openDialogCreate, onDialogCreateChange, handleGetList }) => {
  const { handleCreateMonitor } = useManagenent();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const contentRef = useRef<HTMLDivElement>(null);

  const [inputValueKey, setInputValueKey] = useState('');
  const [inputValueMail, setInputValueMail] = useState('');

  const schema = yup.object().shape({
    name: yup.string().required(t('validation.fieldRequired')),
    key: yup.array().of(yup.string().required()).min(1, t('validation.fieldRequired')),
    email: yup
      .array()
      .of(yup.string().required(t('validation.fieldRequired')).email(t('validation.invalidEmail')))
      .min(1, t('validation.fieldRequired')),
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      key: [],
      email: [],
    },
  });

  const addSuccess = () => {
    setValue('name', '');
    setValue('key', []);
    setValue('email', []);
    onDialogCreateChange(false);
    if (searchParams.get('page') === '1') {
      handleGetList();
    } else {
      setSearchParams({ page: '1' });
    }
  };

  const onSubmit = async (data: any) => {
    const params = { name: data.name, keywords: data.key, emails: data.email };
    await handleCreateMonitor(params, addSuccess);
  };

  useEffect(() => {
    if (openDialogCreate && contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [openDialogCreate]);

  const handleInputKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    value: string[],
    onChange: (newValues: string[]) => void,
    inputValue: string,
    setInputValue: (value: string) => void,
  ) => {
    if (event.key === 'Enter' && inputValue) {
      event.preventDefault();
      onChange([...value, inputValue]);
      setInputValue('');
    }
  };

  return (
    <Dialog
      open={openDialogCreate}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        onDialogCreateChange(false);
        setValue('key', []);
        setValue('email', []);
      }}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: { xs: '90vw', md: '60vw' },
          },
        },
        overflow: 'visible',
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <DialogTitle>
        <Typography sx={{ fontSize: '2rem', marginTop: 1 }}>{t('management.keywordMonitor.addMonitor')}</Typography>
      </DialogTitle>
      <DialogContent ref={contentRef}>
        <Box sx={{ backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{}}>
              <Controller
                name="name"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    type="text"
                    style={{ width: '100%' }}
                    placeholder={t('placeholder.groupName')}
                    sx={{ padding: 2 }}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                    id="input-with-icon-textfield"
                    variant="outlined"
                  />
                )}
              />
            </Box>
            <Box
              sx={{
                mt: 2,
                padding: 2,
                width: '100%',
                borderRadius: 0.5,
                flexDirection: 'column',
                backgroundColor: 'action.hover',
              }}
            >
              <Controller
                name="key"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    multiple
                    options={[]}
                    freeSolo
                    inputValue={inputValueKey}
                    onInputChange={(event, newValue) => {
                      if (event?.type !== 'blur') {
                        setInputValueKey(newValue);
                      }
                    }}
                    onChange={(event, value) => {
                      field.onChange(value);
                    }}
                    sx={{
                      '.MuiChip-label': {
                        fontSize: '1.2rem',
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t('placeholder.keyword')}
                        error={!!errors.key}
                        helperText={errors.key?.message}
                        onKeyDown={
                          (event: React.KeyboardEvent<HTMLInputElement>) =>
                            handleInputKeyDown(
                              event,
                              (field.value ?? []).filter((val: string): val is string => val !== undefined),
                              (newValue) => field.onChange(newValue),
                              inputValueKey,
                              setInputValueKey,
                            )
                          // eslint-disable-next-line react/jsx-curly-newline
                        }
                      />
                    )}
                  />
                )}
              />
              <Typography sx={{ fontSize: '1.2rem', color: 'text.disabled', mt: 1 }}>{t('smcc.enterToAdd')}</Typography>
            </Box>
            <Box
              sx={{
                mt: 4,
                padding: 2,
                width: '100%',
                borderRadius: 0.5,
                flexDirection: 'column',
                backgroundColor: 'action.hover',
              }}
            >
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    multiple
                    options={[]}
                    freeSolo
                    inputValue={inputValueMail}
                    onInputChange={(event, newValue) => {
                      if (event?.type !== 'blur') {
                        setInputValueMail(newValue);
                      }
                    }}
                    onChange={(event, value) => {
                      field.onChange(value);
                    }}
                    sx={{
                      '.MuiChip-label': {
                        fontSize: '1.2rem',
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t('placeholder.email')}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        onKeyDown={
                          (event: React.KeyboardEvent<HTMLInputElement>) =>
                            handleInputKeyDown(
                              event,
                              (field.value ?? []).filter((val: string): val is string => val !== undefined),
                              (newValue) => field.onChange(newValue),
                              inputValueMail,
                              setInputValueMail,
                            )
                          // eslint-disable-next-line react/jsx-curly-newline
                        }
                      />
                    )}
                  />
                )}
              />
              <Typography sx={{ fontSize: '1.2rem', color: 'text.disabled', mt: 1 }}>{t('smcc.enterToAdd')}</Typography>
            </Box>

            <Button type="submit" variant="contained" color="primary" sx={{ marginTop: 3 }}>
              {t('action.add')}
            </Button>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default AddMonitor;
