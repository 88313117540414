import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  TextField,
  Typography,
  DialogTitle,
  DialogContent,
  Dialog,
  SelectChangeEvent,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import CancelButton from '@/components/common/Button/CancelButton';
import PrimaryButton from '@/components/common/Button/PrimaryButton';
import { Transition } from '@/components/common/Transition';
import ConfirmCancelModal from '@/components/Modal/ConfirmCancelModal';
import useAuth from '@/Hooks/api/useAuth';
import useNotify from '@/Hooks/common/useNotify';
import { CompanyType, UserType } from '@/types/management';

interface CreateCompanyProps {
  openDialogCreate: boolean;
  onOpenDialogCreateChange: (newValue: boolean) => void;
  listUserGroups: UserType[][];
  listCompanies: CompanyType[];
  handleGetUser: () => Promise<void>;
  handleGetCompany: () => Promise<void>;
}

const CreateCompany: React.FC<CreateCompanyProps> = ({
  openDialogCreate,
  onOpenDialogCreateChange,
  listUserGroups,
  listCompanies,
  handleGetUser,
  handleGetCompany,
}) => {
  const { t } = useTranslation();
  const { onNotify } = useNotify();
  const { handleEditCompany } = useAuth();

  const uniqueGroupNames = Array.from(new Set(listUserGroups.flatMap((group) => group.map((user) => user.group_name))));

  const [openDialogCancel, setOpenDialogCancel] = useState(false);
  const [group, setGroup] = useState(uniqueGroupNames[0] || '');

  const validationSchema = yup.object().shape({
    companyName: yup.string().required(t('validation.fieldRequired')),
    mainDomain: yup
      .string()
      .required(t('validation.fieldRequired'))
      .matches(/^(?!:\/\/)([a-zA-Z0-9-]+\.)+([a-zA-Z]{2,})(:[0-9]+)?(\/[^\s]*)?$/, t('validation.invalidDomain')),
    clientName: yup.string().required(t('validation.fieldRequired')),
    country: yup.string().required(t('validation.fieldRequired')),
    address: yup.string().required(t('validation.fieldRequired')),
    groupName: yup.string(),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      companyName: '',
      mainDomain: '',
      clientName: '',
      country: '',
      address: '',
      groupName: '',
    },
  });

  const navigateSuccess = () => {
    onOpenDialogCreateChange(false);
    reset();
    handleGetUser();
    handleGetCompany();
  };

  const onSubmit = (data: any) => {
    const isDomainExists = listCompanies.some(
      (domainCheck: CompanyType) => domainCheck.main_domain === data.mainDomain.trim(),
    );
    if (isDomainExists) {
      onNotify('warning', t('notify.existedDomain'), 3000);
    } else {
      const params = {
        info_company: {
          name_company: data.companyName.trim(),
          name_client: data.clientName.trim(),
          main_domain: data.mainDomain.trim(),
          country: data.country.trim(),
          address: data.address.trim(),
        },
        group_name: group,
      };
      const body = {
        params,
        type: 'add',
      };
      handleEditCompany(body, navigateSuccess);
    }
  };

  const handleChangeGroup = (event: SelectChangeEvent) => {
    setGroup(event.target.value);
  };

  const handleCancelClick = () => {
    if (isDirty) {
      setOpenDialogCancel(true);
    } else {
      onOpenDialogCreateChange(false);
    }
  };

  const handleCancel = () => {
    onOpenDialogCreateChange(false);
    setOpenDialogCancel(false);
    reset();
  };

  return (
    <>
      <Dialog
        open={openDialogCreate}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
          onOpenDialogCreateChange(false);
        }}
        disableEscapeKeyDown
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '800px',
            },
          },
          overflow: 'visible',
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <DialogTitle>
          <Typography sx={{ fontSize: '2rem', marginTop: 1 }}>{t('action.addCompany')}</Typography>
        </DialogTitle>

        <DialogContent>
          <Box sx={{ width: '100%', marginBottom: 2 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                padding: 2,
                borderRadius: '10px',
                backgroundColor: 'action.hover',
              }}
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} sx={{ marginTop: 1 }}>
                  <Grid item xs={12} md={6}>
                    <FormControl variant="outlined" sx={{ width: '100%', justifyContent: 'flex-end' }}>
                      <InputLabel id="demo-simple-select-standard-label" sx={{ fontSize: '0.85rem' }}>
                        {t('placeholder.group')}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={group}
                        onChange={handleChangeGroup}
                        label="Group"
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              boxShadow: 5,
                            },
                          },
                        }}
                        sx={{
                          '.MuiInputBase-input': {
                            padding: '15.6px 14px',
                          },
                        }}
                      >
                        {uniqueGroupNames.map((name: string) => (
                          <MenuItem
                            key={name}
                            value={name}
                            sx={{
                              '&.MuiMenuItem-root': {
                                '&.Mui-selected': {
                                  backgroundColor: 'action.selected',
                                },
                              },
                            }}
                          >
                            <Typography variant="body2">{name}</Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="companyName"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          type="text"
                          style={{ width: '100%' }}
                          label={t('placeholder.companyName')}
                          error={!!fieldState.error}
                          helperText={fieldState.error ? fieldState.error.message : null}
                          id="input-with-icon-textfield"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginTop: { md: 1, xs: 0 } }}>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="mainDomain"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          type="text"
                          style={{ width: '100%' }}
                          label={t('placeholder.mainDomain')}
                          error={!!fieldState.error}
                          helperText={fieldState.error ? fieldState.error.message : null}
                          id="input-with-icon-textfield"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="clientName"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          type="text"
                          style={{ width: '100%' }}
                          label={t('placeholder.clientName')}
                          error={!!fieldState.error}
                          helperText={fieldState.error ? fieldState.error.message : null}
                          id="input-with-icon-textfield"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginTop: { md: 1, xs: 0 } }}>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="address"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          type="text"
                          style={{ width: '100%' }}
                          label={t('placeholder.address')}
                          error={!!fieldState.error}
                          helperText={fieldState.error ? fieldState.error.message : null}
                          id="input-with-icon-textfield"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="country"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          type="text"
                          style={{ width: '100%' }}
                          label={t('placeholder.country')}
                          error={!!fieldState.error}
                          helperText={fieldState.error ? fieldState.error.message : null}
                          id="input-with-icon-textfield"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Box>
          <Box sx={{ margin: 'auto', mt: 2, display: 'flex', justifyContent: 'flex-end', mr: 3 }}>
            <PrimaryButton handleClick={handleSubmit(onSubmit)} label={t('action.create')} />
            <CancelButton handleClick={handleCancelClick} />
          </Box>
        </DialogContent>
        <ConfirmCancelModal
          openDialogCancel={openDialogCancel}
          onOpenDialogCancelChange={setOpenDialogCancel}
          handleCancel={handleCancel}
        />
      </Dialog>
    </>
  );
};
export default CreateCompany;
