import { useEffect, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { Button, Box, debounce, InputBase, useTheme, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import usePhishing from '@/Hooks/api/usePhishing';
import { CampaignTypes } from '@/types/phishing';
import { PAGE_SIZE } from '@/utils/constants';
import { decrypt } from '@/utils/crypto';

import GetContent from './CreateCampaign/GetContent';
import TableCampaign from './TableCampaign';

const Campaign = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));
  const { handleListCampaign } = usePhishing();
  const [searchParams, setSearchParams] = useSearchParams();

  const [openDialogGetContent, setOpenDialogGetContent] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [listCampaign, setListCampaign] = useState<CampaignTypes[]>([]);
  const [totalCampaign, setTotalCampaign] = useState(0);
  const [keyword, setKeyword] = useState('');

  const searchValue = useMemo(() => searchParams.get('search') || '', [searchParams]);

  // const handleGetListMalware = async (pageValue: number) => {
  //   const dataRes = await handleListMalware({ page: pageValue });
  //   const { count, results } = dataRes;
  //   setListMalware(results);
  //   setCountMalware(count);
  // };

  useEffect(() => {
    setKeyword(searchValue);
  }, [searchValue]);

  const handleGetListCampaign = async () => {
    const params = {
      search_name: searchParams.get('search') || '',
      page: Number(searchParams.get('page') || 1),
      page_size: PAGE_SIZE,
    };
    const dataRes = await handleListCampaign(params);
    const { count, results } = dataRes;
    setListCampaign(results);
    setTotalCampaign(count);
    setLoadingFilter(false);
  };

  useEffect(() => {
    handleGetListCampaign();
  }, [searchParams]);

  const debouncedSearch = useMemo(
    () =>
      debounce((searchTerm) => {
        const trimmedSearchTerm = searchTerm.trim();
        const newParams = new URLSearchParams(searchParams);
        if (trimmedSearchTerm !== '' && trimmedSearchTerm.length > 2) {
          newParams.set('search', trimmedSearchTerm);
          newParams.set('page', '1');
        } else {
          newParams.delete('search');
        }
        setSearchParams(newParams);
        setLoadingFilter(false);
      }, 2000),
    [searchParams, setSearchParams],
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value;
    setKeyword(searchTerm);
    const trimmedSearchTerm = searchTerm.trim();
    if (trimmedSearchTerm.length > 2 || trimmedSearchTerm === '') {
      setLoadingFilter(true);
      debouncedSearch(searchTerm);
    }
  };

  const clearSearch = () => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set('page', '1');
    newParams.delete('search');
    setSearchParams(newParams);
    setKeyword('');
  };

  return (
    <>
      <Box sx={{ mt: { xs: 1, md: 3 } }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: 2,
            marginBottom: inforUser.role === 'super admin' ? '30px' : 0,
          }}
        >
          <Box
            sx={{
              width: '100%',
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: 1,
            }}
          >
            <InputBase
              sx={{
                width: '100%',
                ml: 1,
                flex: 1,
                '.MuiInputBase-input': {
                  padding: '12px 12px',
                },
              }}
              placeholder={t('placeholder.typeSearch')}
              inputProps={{
                'aria-label': 'filter company',
              }}
              endAdornment={
                <>
                  {loadingFilter && <CircularProgress color="inherit" size={20} sx={{ mr: 3 }} />}
                  {searchValue && (
                    <ClearIcon
                      onClick={clearSearch}
                      sx={{
                        mr: 3,
                        color: 'text.disabled',
                        '&:hover': {
                          cursor: 'pointer',
                        },
                      }}
                    />
                  )}
                </>
              }
              value={keyword}
              onChange={handleInputChange}
            />
          </Box>

          <Button
            variant="contained"
            fullWidth
            type="button"
            onClick={() => {
              setOpenDialogGetContent(true);
            }}
            sx={{
              width: 'fit-content',
              ml: 2,
              borderRadius: '5px',
              textTransform: 'none',
              backgroundColor: 'action.selected',
              color: '#02bb9f',
              '&:hover': {
                backgroundColor: 'action.hover',
              },
              whiteSpace: 'nowrap',
            }}
          >
            <AddIcon sx={{ mr: 0.5 }} />
            {t('action.addCampaign')}
          </Button>
        </Box>
        <Box
          sx={{
            width: '100%',
            backgroundColor: 'background.main',
            padding: 2,
            borderRadius: '3px',
            mt: 3,
            boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
          }}
        >
          <TableCampaign
            campaign={listCampaign}
            totalCampaign={totalCampaign}
            handleGetListCampaign={handleGetListCampaign}
            loadingFilter={loadingFilter}
          />
        </Box>
      </Box>
      <GetContent
        openDialogGetContent={openDialogGetContent}
        onOpenDialogGetContentChange={setOpenDialogGetContent}
        handleGetListCampaign={handleGetListCampaign}
      />
    </>
  );
};
export default Campaign;
