import { useEffect, useRef, useState } from 'react';

import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import HomeIcon from '@mui/icons-material/Home';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Card, CardContent, Grid, Typography, Button } from '@mui/material';

import useManagenent from '@/Hooks/api/useManagenent';
import { CourseTypes, GroupedCourseTypes } from '@/types/management';

import DetailCourse from './DetailCourse';
import LazyImage from './LazyImage';

interface DetailCourseProps {
  onCategoryChange: (newValue: boolean) => void;
  groupedCourse: GroupedCourseTypes;
  typeSelected: string;
  listCourses: GroupedCourseTypes[];
}

const Category: React.FC<DetailCourseProps> = ({ onCategoryChange, groupedCourse, typeSelected, listCourses }) => {
  const { handleViewCourse } = useManagenent();

  const PAGE_SIZE = 15;

  const [openDialogDetail, setOpenDialogDetail] = useState(false);
  const [courseSelected, setCourseSelected] = useState<CourseTypes>();
  const [videoDurations, setVideoDurations] = useState<{ [key: string]: string }>({});
  const [visibleCourses, setVisibleCourses] = useState<CourseTypes[]>(groupedCourse.list_course.slice(0, PAGE_SIZE));
  const [page, setPage] = useState(1);
  const observerRef = useRef<HTMLDivElement | null>(null);

  const handleCountViews = async (id: string) => {
    await handleViewCourse({ id });
  };

  useEffect(() => {
    // Lấy thời lượng cho tất cả các video trong danh sách khóa học
    groupedCourse.list_course.forEach((course: CourseTypes) => {
      const videoElement = document.createElement('video');
      videoElement.src = `${course.url_storage}/${course.bucket_name}/${course.detail_link.link_video}`;
      videoElement.addEventListener('loadedmetadata', () => {
        const { duration } = videoElement;
        const hours = Math.floor(duration / 3600);
        const minutes = Math.floor((duration % 3600) / 60);
        const seconds = Math.floor(duration % 60);

        const formattedDuration = `${String(hours)}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
          2,
          '0',
        )}`;

        setVideoDurations((prevDurations) => ({
          ...prevDurations,
          [course.id]: formattedDuration,
        }));
      });
    });
  }, []);

  useEffect(() => {
    if (!observerRef.current) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadMoreCourses();
        }
      },
      { threshold: 0.5 },
    );

    observer.observe(observerRef.current);

    return () => observer.disconnect();
  }, [visibleCourses]);

  const loadMoreCourses = () => {
    const nextPage = page + 1;
    const nextCourses = groupedCourse.list_course.slice(0, nextPage * PAGE_SIZE);

    if (nextCourses.length > visibleCourses.length) {
      setVisibleCourses(nextCourses);
      setPage(nextPage);
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Box sx={{ mt: 2, mb: 8 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            onClick={() => {
              onCategoryChange(false);
            }}
            sx={{ display: 'flex', cursor: 'pointer' }}
          >
            <HomeIcon
              sx={{
                fontSize: '2.5rem',
                color: 'text.secondary',
                '&:hover': { color: 'text.primary' },
              }}
            />

            <ArrowForwardIosIcon sx={{ fontSize: '1.2rem', ml: 1, color: 'text.secondary' }} />
          </Button>

          <Typography variant="h6" sx={{ ml: 1 }}>
            {groupedCourse.type}
          </Typography>
        </Box>

        <Grid container spacing={4} sx={{ mt: '-15px !important' }}>
          {visibleCourses.map((course: CourseTypes) => (
            <Grid item xs={6} sm={4} md={4} lg={2.4} key={course.id}>
              <Card
                sx={{
                  borderRadius: 2,
                  transition: 'all 0.2s',
                  backgroundColor: 'background.main',
                  cursor: 'pointer',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: '0 4px 8px #0000001a',
                  },
                  minHeight: '300px',
                  maxHeight: '350px',
                }}
                onClick={() => {
                  setCourseSelected(course);
                  setOpenDialogDetail(true);
                  handleCountViews(course.id);
                }}
              >
                <Box sx={{ position: 'relative' }}>
                  <LazyImage
                    key={course.id}
                    src={`${course.url_storage}/${course.bucket_name}/${course.detail_link.link_thumbnail}`}
                    alt="course thumbnail"
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      left: 0,
                      bottom: '45px',
                      padding: { xs: 2, sm: 3 },
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <PlayCircleFilledIcon
                      sx={{
                        fontSize: '7rem',
                        cursor: 'pointer',
                        color: 'rgba(0,0,0,0.5)',
                        '&:hover': {
                          color: 'error.main',
                        },
                      }}
                    />
                  </Box>
                </Box>

                <CardContent>
                  <Typography
                    gutterBottom
                    component="div"
                    sx={{
                      fontWeight: 500,
                      cursor: 'pointer',
                      color: 'text.secondary',
                      '&:hover': {
                        color: 'primary.main',
                      },
                      textTransform: 'capitalize',
                      minHeight: '50px',
                      maxHeight: '50px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2,
                    }}
                  >
                    {course.title}
                  </Typography>

                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <Box sx={{ display: 'flex', color: 'text.secondary' }}>
                      <VisibilityIcon sx={{ fontSize: '2rem' }} />
                      <Typography variant="body2" sx={{ ml: 1, alignSelf: 'center' }}>
                        {course.views}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', color: 'text.secondary' }}>
                      <AccessTimeFilledIcon sx={{ fontSize: '2rem' }} />
                      <Typography variant="body2" sx={{ ml: 1, alignSelf: 'center' }}>
                        {videoDurations[course.id] || 'Loading...'}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        {/* Phần tử để trigger Intersection Observer */}
        <div ref={observerRef} style={{ height: 10 }} />

        {/* {loading && <CircularProgress />} */}
      </Box>

      {courseSelected && (
        <DetailCourse
          openDialogDetail={openDialogDetail}
          onDialogDetailChange={setOpenDialogDetail}
          detail={courseSelected}
          typeSelected={typeSelected}
          listCourses={listCourses ?? ([] as GroupedCourseTypes[])}
        />
      )}
    </Box>
  );
};

export default Category;
