import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  StepLabel,
  Stepper,
  Step,
  useTheme,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import * as _ from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import CancelButton from '@/components/common/Button/CancelButton';
import ConfirmCancelModal from '@/components/Modal/ConfirmCancelModal';
import useAuth from '@/Hooks/api/useAuth';
import { DomainTypes } from '@/types/management';
import { decrypt } from '@/utils/crypto';

interface CreateUserSuperAdminProps {
  openDialogCreate: boolean;
  onOpenDialogCreateChange: (newValue: boolean) => void;
  handleGetUser: () => Promise<void>;
  handleGetCompany: () => Promise<void>;
  onTabChange: (newValue: number) => void;
  listDomain: DomainTypes[];
  onListDomainChange: React.Dispatch<React.SetStateAction<DomainTypes[]>>;
}

const CreateUserSuperAdmin: React.FC<CreateUserSuperAdminProps> = ({
  openDialogCreate,
  onOpenDialogCreateChange,
  handleGetUser,
  handleGetCompany,
  onTabChange,
  listDomain,
  onListDomainChange,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { handleCreateUser, handleCheckUserExist } = useAuth();
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));

  const [valueRole, setValueRole] = useState('manager');
  const [openDialogCancel, setOpenDialogCancel] = useState(false);

  const handleChangeRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueRole((event.target as HTMLInputElement).value);
  };

  const navigateSuccess = () => {
    handleGetUser();
    handleGetCompany();
    onOpenDialogCreateChange(false);
    reset();
    onTabChange(0);
    onListDomainChange([]);
  };

  const validationSchema = yup.object().shape({
    email: yup.string().required(t('validation.fieldRequired')),
    expireDate: yup.string().required(t('validation.fieldRequired')),
    groupName: yup.string().required(t('validation.fieldRequired')),
  });

  const { handleSubmit, control, setError, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: '',
      expireDate: '',
      groupName: '',
    },
  });

  const onSubmit = (data: any) => {
    const params = {
      username: data.email.trim(),
      email: data.email.trim(),
      expire_date_license: data.expireDate.trim(),
      permission: valueRole,
      group_name: data.groupName.trim(),
      list_company: listDomain,
    };

    const navigateSuccessCheckUser = () => {
      handleCreateUser([params], navigateSuccess);
    };

    const navigateFailCheckUser = (errorMessage: string) => {
      setError('email', {
        type: 'manual',
        message: errorMessage || 'Email đã tồn tại',
      });
    };
    handleCheckUserExist({ username: data.email }, navigateSuccessCheckUser, navigateFailCheckUser);
  };

  const handleCancelClick = () => {
    setOpenDialogCancel(true);
  };

  const handleCancel = () => {
    onOpenDialogCreateChange(false);
    setOpenDialogCancel(false);
    reset();
    onTabChange(0);
    onListDomainChange([]);
  };

  const backToDomain = () => {
    reset();
    onTabChange(0);
  };

  return (
    <>
      <Dialog
        open={openDialogCreate}
        keepMounted
        onClose={(event, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
          onOpenDialogCreateChange(false);
        }}
        disableEscapeKeyDown
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '800px',
            },
          },
          overflow: 'visible',
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <DialogTitle>
          <Stepper activeStep={1} alternativeLabel>
            <Step sx={{ mt: 2, cursor: 'pointer' }}>
              <StepLabel onClick={backToDomain}>{t('action.addClient')}</StepLabel>
            </Step>
            <Step sx={{ mt: 2 }}>
              <StepLabel>{t('action.addUser')}</StepLabel>
            </Step>
          </Stepper>
        </DialogTitle>

        <DialogContent>
          <Box sx={{ borderRadius: 2, mt: 1, padding: 2, backgroundColor: 'action.hover' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
              }}
            >
              {!_.isEmpty(listDomain) && (
                <>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Button
                      type="button"
                      variant="outlined"
                      color="info"
                      onClick={backToDomain}
                      sx={{
                        textTransform: 'initial',
                        fontSize: '1.2rem',
                        fontWeight: '500',
                        borderRadius: '5px',
                      }}
                    >
                      {t('action.back')}
                    </Button>
                  </Box>

                  <Box sx={{ borderRadius: 1, mt: 2, padding: 1, backgroundColor: 'action.hover' }}>
                    <TableContainer>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t('table.company.company')}</TableCell>
                            <TableCell>{t('table.company.client')}</TableCell>
                            <TableCell>{t('table.company.domain')}</TableCell>
                            <TableCell>{t('table.company.country')}</TableCell>
                            <TableCell>{t('table.company.address')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {listDomain.map((row: DomainTypes) => (
                            <TableRow key={row.main_domain} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell>{row.name_company}</TableCell>
                              <TableCell>{row.name_client}</TableCell>
                              <TableCell>{row.main_domain}</TableCell>
                              <TableCell>{row.country}</TableCell>
                              <TableCell>{row.address}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </>
              )}
            </Box>

            <Box
              sx={{
                width: '100%',
                marginTop: 4,
                marginBottom: 2,
                padding: 2,
                borderRadius: '10px',
                border: `1px solid ${theme.palette.divider}`,
              }}
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type="text"
                      style={{ width: '100%' }}
                      label={t('placeholder.email')}
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      id="input-with-icon-textfield"
                      variant="outlined"
                    />
                  )}
                />

                <Box
                  sx={{
                    marginTop: 3,
                  }}
                >
                  <FormControl
                    sx={{
                      marginRight: '20px',
                      '.MuiFormLabel-root': {
                        fontSize: '1rem',
                      },
                    }}
                  >
                    <FormLabel id="demo-controlled-radio-buttons-group">{t('role.role')}</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={valueRole}
                      onChange={handleChangeRole}
                    >
                      {inforUser?.role === 'super admin' && (
                        <FormControlLabel
                          value="manager"
                          control={<Radio />}
                          label={t('role.manager')}
                          sx={{
                            '.MuiTypography-root': {
                              fontSize: '1.4rem',
                            },
                          }}
                        />
                      )}
                    </RadioGroup>
                  </FormControl>
                </Box>

                <Box
                  sx={{
                    marginTop: 3,
                  }}
                >
                  <Controller
                    name="groupName"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        type="text"
                        style={{ width: '100%' }}
                        label={t('placeholder.groupName')}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        id="input-with-icon-textfield"
                        variant="outlined"
                      />
                    )}
                  />
                </Box>
                <Box
                  sx={{
                    marginTop: 4,
                  }}
                >
                  <Controller
                    name="expireDate"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        type="date"
                        style={{ width: '100%' }}
                        label={t('placeholder.expireDate')}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        id="input-with-icon-textfield"
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        inputProps={{
                          min: new Date().toISOString().split('T')[0],
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                      />
                    )}
                  />
                </Box>
              </form>
            </Box>
            <Box sx={{ margin: 'auto', mt: 4, display: 'flex', justifyContent: 'flex-end', mr: 3 }}>
              <Button
                type="submit"
                name="addUser"
                variant="contained"
                color="info"
                onClick={handleSubmit(onSubmit)}
                sx={{
                  textTransform: 'initial',
                  fontSize: '1.2rem',
                }}
              >
                {t('action.addUser')}
              </Button>
              <CancelButton handleClick={handleCancelClick} />
            </Box>
            <ConfirmCancelModal
              openDialogCancel={openDialogCancel}
              onOpenDialogCancelChange={setOpenDialogCancel}
              handleCancel={handleCancel}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default CreateUserSuperAdmin;
