import { useState } from 'react';

import { DomainTypes } from '@/types/management';

import CreateDomainSuperAdmin from './CreateDomainSuperAdmin';
import CreateUserSuperAdmin from './CreateUserSuperAdmin';

interface CreateRoleSuperAdminProps {
  openDialogCreate: boolean;
  onOpenDialogCreateChange: (newValue: boolean) => void;
  handleGetUser: () => Promise<void>;
  handleGetCompany: () => Promise<void>;
}

const CreateRoleSuperAdmin: React.FC<CreateRoleSuperAdminProps> = ({
  openDialogCreate,
  onOpenDialogCreateChange,
  handleGetUser,
  handleGetCompany,
}) => {
  const [listDomain, setListDomain] = useState<DomainTypes[]>([]);
  const [tab, setTab] = useState(0);

  return (
    <>
      {tab === 0 ? (
        <CreateDomainSuperAdmin
          openDialogCreate={openDialogCreate}
          onOpenDialogCreateChange={onOpenDialogCreateChange}
          onTabChange={setTab}
          listDomain={listDomain}
          onListDomainChange={setListDomain}
        />
      ) : (
        <CreateUserSuperAdmin
          openDialogCreate={openDialogCreate}
          onOpenDialogCreateChange={onOpenDialogCreateChange}
          handleGetUser={handleGetUser}
          handleGetCompany={handleGetCompany}
          onTabChange={setTab}
          listDomain={listDomain}
          onListDomainChange={setListDomain}
        />
      )}
    </>
  );
};
export default CreateRoleSuperAdmin;
