import { useTranslation } from 'react-i18next';

import { httpStatus } from '@/configs/Enums/httpStatus';
import useManagementService from '@/services/management';
import { ReportNewsParams } from '@/types/management';

import useLoadingGif from '../common/useLoadingGif';
import useNotify from '../common/useNotify';

type ResSuccessType = () => void;

const useManagenent = () => {
  const {
    listNews,
    editNews,
    deleteNews,
    getListPermissionIdentity,
    permissionIdentity,
    getList2FA,
    disable2FA,
    uploadCourse,
    editCourse,
    getListCoursesManage,
    getListCourses,
    deleteCourse,
    viewCourse,
    getListReport,
    sendReportNews,
    getListSession,
    uploadSession,
    deleteSession,
    addAPI,
    updateAPI,
    deleteAPI,
    detailAPI,
    getAPI,
    getListMonitor,
    createtMonitor,
    updateMonitor,
    deleteMonitor,
    triggerMonitor,
  } = useManagementService();
  const { setLoadingGif } = useLoadingGif();
  const { onNotify } = useNotify();
  const { t } = useTranslation();

  const handleListNews = async (params: {}) => {
    setLoadingGif(true);
    const res: any = await listNews(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      setLoadingGif(false);
      return data;
    }
  };

  const handleUpdateNews = async (body: any, updateSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await editNews(body);
    const { status } = res || {};
    if (status === httpStatus.StatusOK) {
      onNotify('success', t('notify.updateNews'), 3000);
      updateSuccess();
      setLoadingGif(false);
    }
  };

  const handleDeleteNews = async (body: any, deleteSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await deleteNews(body);
    const { status } = res || {};
    if (status === httpStatus.StatusOK) {
      onNotify('success', t('notify.deleteNews'), 3000);
      deleteSuccess();
      setLoadingGif(false);
    }
  };

  const handleGetListPermissionIdentity = async (body: any) => {
    setLoadingGif(true);
    const res: any = await getListPermissionIdentity(body);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      setLoadingGif(false);
      return data;
    }
  };

  const handlePermissionIdentity = async (body: any, navigateSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await permissionIdentity(body);
    const { status } = res || {};
    switch (status) {
      case httpStatus.StatusOK:
        navigateSuccess();
        setLoadingGif(false);
        break;
      default:
        break;
    }
  };

  const handleGetList2FA = async (body: any) => {
    setLoadingGif(true);
    const res: any = await getList2FA(body);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      setLoadingGif(false);
      return data;
    }
  };

  const handleDisable2FA = async (body: any, deleteSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await disable2FA(body);
    const { status } = res || {};
    switch (status) {
      case httpStatus.StatusOK:
        onNotify('success', t('notify.removePermission'), 3000);
        deleteSuccess();
        setLoadingGif(false);
        break;
      default:
        break;
    }
  };

  const handleUploadCourse = async (body: any) => {
    const res: any = await uploadCourse(body);
    const { status } = res || {};
    switch (status) {
      case httpStatus.StatusOK:
        //
        break;
      default:
        break;
    }
  };

  const handleGetListCoursesManage = async (body: any) => {
    setLoadingGif(true);
    const res: any = await getListCoursesManage(body);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      setLoadingGif(false);
      return data;
    }
  };

  const handleGetListCourses = async (body: any) => {
    setLoadingGif(true);
    const res: any = await getListCourses(body);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      setLoadingGif(false);
      return data;
    }
  };

  const handleUpdateCourse = async (body: any) => {
    const res: any = await editCourse(body);
    const { status } = res || {};
    switch (status) {
      case httpStatus.StatusOK:
        //
        break;
      default:
        break;
    }
  };

  const handleViewCourse = async (body: any) => {
    const res: any = await viewCourse(body);
    const { status } = res || {};
    switch (status) {
      case httpStatus.StatusOK:
        //
        break;
      default:
        break;
    }
  };

  const handleDeleteCourse = async (body: any, deleteSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await deleteCourse(body);
    const { status } = res || {};
    if (status === httpStatus.StatusOK) {
      onNotify('success', t('notify.deleteNews'), 3000);
      deleteSuccess();
      setLoadingGif(false);
    }
  };

  const handleGetListReport = async (params: {}) => {
    setLoadingGif(true);
    const res: any = await getListReport(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      setLoadingGif(false);
      return data;
    }
  };

  const handleSendReportNews = async (body: ReportNewsParams, sendReportSuccess: ResSuccessType) => {
    setLoadingGif(true);
    const res: any = await sendReportNews(body);
    const { status } = res || {};
    switch (status) {
      case httpStatus.StatusCreated:
        onNotify('info', t('notify.sendReport'), 3000);
        sendReportSuccess();
        setLoadingGif(false);
        break;
      default:
        break;
    }
  };

  const handleGetListSession = async (params: {}) => {
    setLoadingGif(true);
    const res: any = await getListSession(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      setLoadingGif(false);
      return data;
    }
  };

  const handleUploadSession = async (body: any, uploadSessionSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await uploadSession(body);
    const { status } = res || {};
    switch (status) {
      case httpStatus.StatusOK:
        uploadSessionSuccess();
        setLoadingGif(false);
        onNotify('success', t('notify.uploadSession'), 3000);
        break;
      default:
        break;
    }
  };

  const handleDeleteSession = async (id: string, deleteSessionSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await deleteSession(id);
    const { status } = res || {};
    if (status === httpStatus.StatusOK) {
      deleteSessionSuccess();
      setLoadingGif(false);
    }
  };

  const handleAddAPI = async (body: any, addSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await addAPI(body);
    const { status } = res || {};
    switch (status) {
      case httpStatus.StatusCreated:
        addSuccess();
        setLoadingGif(false);
        onNotify('success', t('notify.createAPIKey'), 3000);
        break;
      case httpStatus.StatusConflict:
        addSuccess();
        setLoadingGif(false);
        onNotify('success', t('notify.existedAPI'), 3000);
        break;
      default:
        break;
    }
  };

  const handleUpdateAPI = async (body: any, updateSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await updateAPI(body);
    const { status } = res || {};
    switch (status) {
      case httpStatus.StatusOK:
        updateSuccess();
        setLoadingGif(false);
        onNotify('success', t('notify.updateAPIKey'), 3000);
        break;
      default:
        break;
    }
  };

  const handleDeleteAPI = async (body: any, deleteSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await deleteAPI(body);
    const { status } = res || {};
    switch (status) {
      case httpStatus.StatusOK:
        deleteSuccess();
        setLoadingGif(false);
        onNotify('success', t('notify.deleteAPIKey'), 3000);
        break;
      default:
        break;
    }
  };

  const handleDetailAPI = async (params: {}) => {
    setLoadingGif(true);
    const res: any = await detailAPI(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      setLoadingGif(false);
      return data;
    }
  };

  const handleGetAPI = async (params: {}) => {
    setLoadingGif(true);
    const res: any = await getAPI(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      setLoadingGif(false);
      return data;
    }
  };

  const handleGetListMonitor = async (params: {}) => {
    setLoadingGif(true);
    const res: any = await getListMonitor(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      setLoadingGif(false);
      return data;
    }
  };

  const handleCreateMonitor = async (body: {}, createSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await createtMonitor(body);
    const { status } = res || {};
    switch (status) {
      case httpStatus.StatusCreated:
        createSuccess();
        setLoadingGif(false);
        onNotify('success', t('notify.createMonitor'), 3000);
        break;
      default:
        break;
    }
  };

  const handleUpdateMonitor = async (id: string, body: {}, updateSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await updateMonitor(id, body);
    const { status } = res || {};
    switch (status) {
      case httpStatus.StatusOK:
        setLoadingGif(false);
        updateSuccess();
        onNotify('success', t('notify.updateMonitor'), 3000);
        break;
      default:
        break;
    }
  };

  const handleDeleteMonitor = async (id: string, deleteSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await deleteMonitor(id);
    const { status } = res || {};
    switch (status) {
      case httpStatus.StatusNoContent:
        deleteSuccess();
        setLoadingGif(false);
        onNotify('success', t('notify.deleteMonitor'), 3000);
        break;
      default:
        break;
    }
  };

  const handleTriggerSendMail = async (body: {}) => {
    setLoadingGif(true);
    const res: any = await triggerMonitor(body);
    const { status } = res || {};
    switch (status) {
      case httpStatus.StatusAccepted:
        setLoadingGif(false);
        onNotify('success', t('notify.sendMonitor'), 3000);
        break;
      default:
        break;
    }
  };

  return {
    handleListNews,
    handleUpdateNews,
    handleDeleteNews,
    handleGetListPermissionIdentity,
    handlePermissionIdentity,
    handleGetList2FA,
    handleDisable2FA,
    handleUploadCourse,
    handleUpdateCourse,
    handleDeleteCourse,
    handleViewCourse,
    handleGetListCoursesManage,
    handleGetListCourses,
    handleGetListReport,
    handleSendReportNews,
    handleGetListSession,
    handleUploadSession,
    handleDeleteSession,
    handleAddAPI,
    handleUpdateAPI,
    handleDeleteAPI,
    handleDetailAPI,
    handleGetAPI,
    handleGetListMonitor,
    handleCreateMonitor,
    handleUpdateMonitor,
    handleDeleteMonitor,
    handleTriggerSendMail,
  };
};

export default useManagenent;
