import { useEffect, useRef, useState } from 'react';

import LinkIcon from '@mui/icons-material/Link';
import { Box, Dialog, DialogContent, DialogTitle, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Transition } from '@/components/common/Transition';
import { DarkwebTypes } from '@/types/dataleak';

interface DarkwebDetailProps {
  openDialogDetail: boolean;
  onDialogDetailChange: (newValue: boolean) => void;
  detailSelected: DarkwebTypes;
}

const DarkwebDetail: React.FC<DarkwebDetailProps> = ({ openDialogDetail, onDialogDetailChange, detailSelected }) => {
  const { t } = useTranslation();
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (openDialogDetail && contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [openDialogDetail]);

  return (
    <Dialog
      open={openDialogDetail}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        onDialogDetailChange(false);
      }}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'flex-start',
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: { sm: '95vw', md: '60vw' },
          },
        },
        overflow: 'visible',
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
      disablePortal
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6" sx={{ marginTop: 1 }}>
            {t('smcc.originalPosts')}
          </Typography>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Link
              href={detailSelected?.url ?? ''}
              target="_blank"
              rel="noopener"
              sx={{
                textDecoration: 'none',

                fontWeight: 400,
                display: 'flex',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              <LinkIcon sx={{ fontSize: '2rem', mr: 1 }} />
              <Typography variant="body2">{t('action.goOriginalPosts')}</Typography>
            </Link>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent ref={contentRef}>
        {detailSelected?.post_screenshot && (
          <Box
            component="img"
            src={detailSelected?.post_screenshot}
            sx={{
              mt: 2,
              height: '350px',
              width: '100%',
              borderRadius: 1,
              objectFit: 'cover',
            }}
          />
        )}
        <Typography
          variant="h6"
          sx={{
            letterSpacing: '0.01em',
            fontWeight: 600,
            mt: 2,
          }}
        >
          {detailSelected?.thread}
        </Typography>

        <Typography variant="body2" sx={{ padding: 1, mt: 0.5, color: 'text.secondary' }}>
          {detailSelected?.post_text}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};
export default DarkwebDetail;
