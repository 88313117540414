import { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Box, Button, InputBase, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import AddCourse from '@/components/Management/learning/AddCourse';
import CourseTable from '@/components/Management/learning/CourseTable';
import useManagenent from '@/Hooks/api/useManagenent';
import { CourseTypes } from '@/types/management';
import { PAGE_SIZE } from '@/utils/constants';

const LearningManagement = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { handleGetListCoursesManage } = useManagenent();
  const [searchParams, setSearchParams] = useSearchParams();

  const [listCourses, setListCourses] = useState<CourseTypes[]>([]);
  const [totalPage, setTotalPage] = useState(0);
  const [openDialogCreate, setOpenDialogCreate] = useState(false);

  const handleGetCourses = async () => {
    const params = {
      page: Number(searchParams.get('page') || 1),
      page_size: PAGE_SIZE,
    };
    const dataRes = await handleGetListCoursesManage(params);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { list_course, total } = dataRes;
    setListCourses(list_course);
    setTotalPage(total);
  };

  useEffect(() => {
    handleGetCourses();
  }, [searchParams.get('page')]);

  return (
    <>
      <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.primary' }}>
        {t('management.training.titleTraining')}
      </Typography>

      <Box sx={{ mt: { xs: 1, md: 3 } }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              width: '100%',
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: 1,
            }}
          >
            <InputBase
              sx={{
                width: '100%',
                ml: 1,
                flex: 1,
                '.MuiInputBase-input': {
                  padding: '10px 10px',
                },
              }}
              placeholder={t('placeholder.typeSearch')}
              inputProps={{
                'aria-label': 'filter company',
              }}
              endAdornment={<></>}
              // value={searchValue}
              // onChange={handleInputChange}
            />
          </Box>

          <Button
            variant="contained"
            fullWidth
            type="button"
            onClick={() => {
              setOpenDialogCreate(true);
            }}
            sx={{
              minWidth: 'fit-content',
              width: 'fit-content',
              ml: 2,
              boxShadow: 'none',
              fontWeight: 600,
              borderRadius: '5px',
              textTransform: 'none',
              backgroundColor: 'action.selected',
              color: '#02bb9f',
              '&:hover': {
                backgroundColor: 'action.hover',
              },
              whiteSpace: 'nowrap',
            }}
          >
            <AddIcon sx={{ mr: 0.5 }} />
            {t('action.addCourse')}
          </Button>
        </Box>
        <Box>
          <CourseTable listCourses={listCourses} handleGetCourses={handleGetCourses} totalPage={totalPage} />
        </Box>
      </Box>
      <AddCourse openDialogCreate={openDialogCreate} onDialogCreateChange={setOpenDialogCreate} />
    </>
  );
};
export default LearningManagement;
