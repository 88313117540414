import MonitorIcon from '@mui/icons-material/Monitor';
import NewspaperOutlinedIcon from '@mui/icons-material/NewspaperOutlined';
import SchoolIcon from '@mui/icons-material/School';
import SecurityIcon from '@mui/icons-material/Security';
import TelegramIcon from '@mui/icons-material/Telegram';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { useTranslation } from 'react-i18next';

import { decrypt } from '@/utils/crypto';

import ItemSideBar from './ItemSideBar';

interface SideBarProps {
  onOpenChange?: (newValue: boolean) => void;
}

const SideBar: React.FC<SideBarProps> = ({ onOpenChange }) => {
  const { t } = useTranslation();
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));

  return (
    <>
      <ItemSideBar icon={SecurityIcon} text="ASM" value="/management/asm" />
      {inforUser.role === 'super admin' && (
        <>
          <ItemSideBar
            onOpenChange={onOpenChange}
            icon={NewspaperOutlinedIcon}
            text={t('home.label.news.news')}
            value="/management/news"
          />
          <ItemSideBar
            onOpenChange={onOpenChange}
            icon={TravelExploreIcon}
            text={t('management.identity.identity')}
            value="/management/identity"
          />
          <ItemSideBar
            onOpenChange={onOpenChange}
            icon={TelegramIcon}
            text="Session Telegram"
            value="/management/session-telegram"
          />
          <ItemSideBar
            onOpenChange={onOpenChange}
            icon={VerifiedUserIcon}
            text={t('management.2fa.2fa')}
            value="/management/two-factor"
          />
          <ItemSideBar
            onOpenChange={onOpenChange}
            icon={SchoolIcon}
            text={t('management.training.training')}
            value="/management/training"
          />
          <ItemSideBar
            onOpenChange={onOpenChange}
            icon={VpnKeyIcon}
            text={t('management.apiKey.apiKey')}
            value="/management/api-key"
          />
          <ItemSideBar
            onOpenChange={onOpenChange}
            icon={MonitorIcon}
            text={t('management.keywordMonitor.label')}
            value="/management/keyword-monitor"
          />
        </>
      )}
    </>
  );
};
export default SideBar;
