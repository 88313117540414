import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  TextField,
  Typography,
  Chip,
  Autocomplete,
  DialogTitle,
  Stepper,
  StepLabel,
  Step,
  DialogContent,
  Dialog,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

import CancelButton from '@/components/common/Button/CancelButton';
import PrimaryButton from '@/components/common/Button/PrimaryButton';
import { Transition } from '@/components/common/Transition';
import ConfirmCancelModal from '@/components/Modal/ConfirmCancelModal';
import usePhishing from '@/Hooks/api/usePhishing';
import { GeneratedContent } from '@/types/phishing';

interface CreateCampaignProps {
  openDialogCreateCampaign: boolean;
  onOpenDialogCreateCampaignChange: (newValue: boolean) => void;
  handleGetListCampaign: () => void;
  generatedContent: GeneratedContent;
}

const CreateCampaign: React.FC<CreateCampaignProps> = ({
  openDialogCreateCampaign,
  onOpenDialogCreateCampaignChange,
  handleGetListCampaign,
  generatedContent,
}) => {
  const { t } = useTranslation();
  const { handleCreateCampaign } = usePhishing();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const steps = [t('asm.phishing.getContentForCampaign'), t('asm.phishing.createCampaign')];

  const [openDialogCancel, setOpenDialogCancel] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const createCampaignSuccess = () => {
    onOpenDialogCreateCampaignChange(false);
    reset();
    if (Array.from(searchParams.keys()).length === 1 && searchParams.get('page') === '1') {
      handleGetListCampaign();
    } else {
      const newParams = new URLSearchParams();
      newParams.set('page', '1');
      navigate({ search: newParams.toString() });
    }
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required(t('validation.fieldRequired')),
    description: yup.string().required(t('validation.fieldRequired')),
    email: yup
      .array()
      .of(yup.string().required(t('validation.fieldRequired')).email(t('validation.invalidEmail')))
      .min(1, t('validation.fieldRequired')),
    content: yup.string().required(t('validation.fieldRequired')),
    landingPage: yup.string().required(t('validation.fieldRequired')),
    subject: yup.string().required(t('validation.fieldRequired')),
    senderName: yup.string().required(t('validation.fieldRequired')),
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: [],
      content: generatedContent.content,
      subject: generatedContent.subject,
      senderName: generatedContent.senderName,
    },
  });

  const onSubmit = (data: any) => {
    const params = {
      name: data.name,
      description: data.description,
      user_list: data.email,
      phishing_content: data.content,
      landing_page: data.landingPage,
      subject: data.subject,
      sender_name: data.senderName,
      attachments: [],
    };

    handleCreateCampaign(params, createCampaignSuccess);
  };

  useEffect(() => {
    if (generatedContent) {
      reset({
        content: generatedContent.content,
        subject: generatedContent.subject,
        senderName: generatedContent.senderName,
      });
    }
  }, [generatedContent, reset]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputValue) {
      event.preventDefault();
      setValue('email', [...(getValues('email') || []), inputValue], { shouldValidate: true });
      setInputValue('');
    }
  };

  const handleCancelClick = () => {
    setOpenDialogCancel(true);
  };

  const handleCancel = () => {
    onOpenDialogCreateCampaignChange(false);
    setOpenDialogCancel(false);
    reset();
  };

  return (
    <>
      <Dialog
        open={openDialogCreateCampaign}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
          onOpenDialogCreateCampaignChange(false);
        }}
        disableEscapeKeyDown
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '800px',
            },
          },
          overflow: 'visible',
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <DialogTitle>
          <Stepper activeStep={1} alternativeLabel>
            {steps.map((label) => (
              <Step sx={{ mt: 2 }} key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </DialogTitle>

        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
              <Controller
                name="name"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    type="text"
                    variant="outlined"
                    style={{ width: '100%', marginTop: '3px' }}
                    label={t('placeholder.campaignName')}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                  />
                )}
              />
            </Box>
            <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
              <Controller
                name="description"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    type="text"
                    variant="outlined"
                    multiline
                    style={{ width: '100%', marginTop: '3px' }}
                    label={t('placeholder.description')}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                  />
                )}
              />
            </Box>
            <Box
              sx={{
                marginTop: 2,
                backgroundColor: 'action.hover',
                padding: 2,
                borderRadius: 1,
              }}
            >
              <Controller
                name="email"
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    multiple
                    id="tags-filled"
                    options={[]}
                    freeSolo
                    value={field.value || []}
                    onChange={(_, value) => field.onChange(value)}
                    getOptionLabel={(option) => option}
                    renderTags={
                      (tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip variant="outlined" label={option} {...getTagProps({ index })} key={option} />
                        ))
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    sx={{
                      mt: 3,
                      '.MuiChip-label': {
                        fontSize: '1.4rem',
                      },
                    }}
                    inputValue={inputValue}
                    onInputChange={(_, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={t('placeholder.email')}
                        onKeyDown={handleKeyDown}
                        error={!!errors.email}
                        helperText={errors.email?.message || errors.email?.[0]?.message}
                      />
                    )}
                  />
                )}
              />

              <Typography sx={{ fontSize: '1.2rem', color: 'text.disabled', mt: 1 }}>{t('smcc.enterToAdd')}</Typography>
            </Box>
            <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
              <Controller
                name="content"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    type="text"
                    variant="outlined"
                    multiline
                    style={{ width: '100%', marginTop: '3px' }}
                    label={t('placeholder.content')}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                  />
                )}
              />
            </Box>
            <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
              <Controller
                name="landingPage"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    type="text"
                    variant="outlined"
                    style={{ width: '100%', marginTop: '3px' }}
                    label={t('placeholder.landingPage')}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                  />
                )}
              />
            </Box>
            <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
              <Controller
                name="subject"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    type="text"
                    variant="outlined"
                    style={{ width: '100%', marginTop: '3px' }}
                    label={t('placeholder.subject')}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                  />
                )}
              />
            </Box>
            <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
              <Controller
                name="senderName"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    type="text"
                    variant="outlined"
                    style={{ width: '100%', marginTop: '3px' }}
                    label={t('placeholder.sender')}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                    onKeyDown={handleKeyDown}
                  />
                )}
              />
            </Box>
          </form>
          <Box sx={{ margin: 'auto', mt: 2, display: 'flex', justifyContent: 'flex-end', mr: 3 }}>
            <PrimaryButton handleClick={handleSubmit(onSubmit)} label={t('action.create')} />
            <CancelButton handleClick={handleCancelClick} />
          </Box>
        </DialogContent>
        <ConfirmCancelModal
          openDialogCancel={openDialogCancel}
          onOpenDialogCancelChange={setOpenDialogCancel}
          handleCancel={handleCancel}
        />
      </Dialog>
    </>
  );
};
export default CreateCampaign;
