import { useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  useTheme,
  Tooltip,
  Pagination,
  Chip,
} from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import ConfirmDeleteModal from '@/components/Modal/ConfirmDeleteModal';
import useManagenent from '@/Hooks/api/useManagenent';
import { EmailMonitorType, KeywordMonitorType, MonitorType } from '@/types/management';
import { PAGE_SIZE } from '@/utils/constants';

import UpdateMonitor from './UpdateMonitor';

interface TableMonitorProps {
  listMonitors: MonitorType[];
  totalItems: number;
  handleGetList: () => Promise<void>;
}

const TableMonitor: React.FC<TableMonitorProps> = ({ listMonitors, totalItems, handleGetList }) => {
  const { handleDeleteMonitor } = useManagenent();
  const { t } = useTranslation();
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();

  const [openDialogUpdate, setOpenDialogUpdate] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [idSelected, setIdSelected] = useState('');
  const [selected, setSelected] = useState<MonitorType>();

  const navigateDeleteSuccess = () => {
    setOpenDialogDelete(false);
    if (searchParams.get('page') === '1') {
      handleGetList();
    } else {
      setSearchParams({ page: '1' });
    }
  };

  const handleDelete = () => {
    handleDeleteMonitor(idSelected, navigateDeleteSuccess);
  };

  const handleChangePage = (pageValue: number) => {
    setSearchParams({ page: pageValue.toString() });
  };

  return (
    <>
      <TableContainer
        sx={{
          padding: 2,
          borderRadius: 2,
          border: `1px solid ${theme.palette.divider}`,
          backgroundColor: 'action.hover',
          mt: 3,
        }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: 'action.hover' }}>
              <TableCell sx={{ width: '20%' }}>{t('placeholder.groupName')}</TableCell>
              <TableCell sx={{ width: '35%' }}>{t('management.keywordMonitor.keyword')}</TableCell>
              <TableCell sx={{ width: '35%' }}>Email</TableCell>
              <TableCell sx={{ width: '10%' }} />
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {!_.isEmpty(listMonitors) ? (
                <>
                  {listMonitors.map((row: MonitorType) => (
                    <TableRow
                      key={row.id}
                      hover
                      tabIndex={-1}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>{row.name}</TableCell>

                      <TableCell>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                          }}
                        >
                          {row.keywords?.map((keyword: KeywordMonitorType) => (
                            <Box key={keyword.id} sx={{ padding: 0.5 }}>
                              <Chip
                                sx={{
                                  backgroundColor: '#d2d6de',
                                  height: '20px',
                                  color: '#2d568b',
                                  fontWeight: 600,
                                  '& .MuiChip-label': {
                                    fontSize: { xs: '1rem', md: '1.1rem' },
                                    padding: { xs: '5px', md: '0px 12px' },
                                  },
                                }}
                                label={keyword.keyword}
                              />
                            </Box>
                          ))}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                          }}
                        >
                          {row.emails?.map((mail: EmailMonitorType) => (
                            <Box key={mail.id} sx={{ padding: 0.5 }}>
                              <Chip
                                sx={{
                                  backgroundColor: '#d2d6de',
                                  height: '20px',
                                  color: '#161C24',
                                  fontWeight: 600,
                                  '& .MuiChip-label': {
                                    fontSize: { xs: '1rem', md: '1.1rem' },
                                    padding: { xs: '5px', md: '0px 12px' },
                                  },
                                }}
                                label={mail.email}
                              />
                            </Box>
                          ))}
                        </Box>
                      </TableCell>

                      <TableCell sx={{ minWidth: '100px', textAlign: 'center' }}>
                        <Box sx={{ display: 'flex' }}>
                          <Tooltip title={t('tooltip.edit')} placement="bottom">
                            <EditIcon
                              sx={{
                                fontSize: '2rem',
                                cursor: 'pointer',
                                '&:hover': {
                                  color: 'primary.main',
                                },
                              }}
                              onClick={() => {
                                setOpenDialogUpdate(true);
                                setSelected(row);
                              }}
                            />
                          </Tooltip>
                          <Tooltip title={t('action.delete')} placement="bottom">
                            <DeleteIcon
                              onClick={() => {
                                setOpenDialogDelete(true);
                                setIdSelected(row.id);
                              }}
                              sx={{
                                ml: 3,
                                fontSize: '2rem',
                                cursor: 'pointer',
                                '&:hover': {
                                  color: 'error.main',
                                },
                              }}
                            />
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={4} sx={{ textAlign: 'center', color: 'text.secondary' }}>
                    {t('notify.noData')}
                  </TableCell>
                </TableRow>
              )}
            </>
          </TableBody>
        </Table>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
          <Pagination
            count={Math.ceil(totalItems / PAGE_SIZE)}
            color="primary"
            onChange={(event, value) => {
              handleChangePage(value);
            }}
            page={Number(searchParams.get('page') || 1)}
          />
        </Box>
      </TableContainer>
      <ConfirmDeleteModal
        openDialogDelete={openDialogDelete}
        onOpenDialogDeleteChange={setOpenDialogDelete}
        handleDelete={handleDelete}
      />
      <UpdateMonitor
        openDialogUpdate={openDialogUpdate}
        onDialogUpdateChange={setOpenDialogUpdate}
        detail={selected ?? ({} as MonitorType)}
        handleGetList={handleGetList}
      />
    </>
  );
};
export default TableMonitor;
