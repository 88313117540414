import { useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  Chip,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  useTheme,
} from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ConfirmDeleteModal from '@/components/Modal/ConfirmDeleteModal';
import useSocial from '@/Hooks/api/useSocial';
import useSetTopicSocial from '@/Hooks/social/useSetTopicSocial';
import { TopicTypes } from '@/types/Social';
import { PAGE_SIZE } from '@/utils/constants';
import { decrypt } from '@/utils/crypto';

import UpdateTopic from './UpdateTopic';

interface TableTopicProps {
  listTopics: TopicTypes[];
  handleGetListTopic: () => void;
  totalTopic: number;
  loadingFilter: boolean;
}

const TableTopic: React.FC<TableTopicProps> = ({ listTopics, handleGetListTopic, loadingFilter, totalTopic }) => {
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));
  const theme = useTheme();
  const { t } = useTranslation();
  const { setTopicSocial } = useSetTopicSocial();
  const { handleDeleteTopic } = useSocial();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [openDialogUpdate, setOpenDialogUpdate] = useState(false);
  const [topicSelected, setTopicSelected] = useState<TopicTypes>();

  const deleteSuccess = () => {
    setOpenDialogDelete(false);
    if (Array.from(searchParams.keys()).length === 1 && searchParams.get('page') === '1') {
      handleGetListTopic();
    } else {
      const newParams = new URLSearchParams(searchParams);
      newParams.set('page', '1');
      setSearchParams(newParams);
    }
  };

  const handleDelete = () => {
    handleDeleteTopic(
      {
        topic_id: topicSelected?.topic_id,
      },
      deleteSuccess,
    );
  };

  const handleChangePage = (pageValue: number) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set('page', String(pageValue));
    navigate({ search: newParams.toString() });
  };

  return (
    <>
      <TableContainer
        sx={{
          padding: 2,
          borderRadius: 2,
          border: `1px solid ${theme.palette.divider}`,
          backgroundColor: 'action.hover',
          mt: 2,
        }}
      >
        <Table aria-label="simple table" sx={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: 'action.hover' }}>
              <TableCell>{t('table.topicSmcc.topic')}</TableCell>
              <TableCell>{t('table.topicSmcc.keyword')}</TableCell>
              <TableCell>{t('table.topicSmcc.createAt')}</TableCell>
              <TableCell>{t('table.topicSmcc.lastUpdate')}</TableCell>
              {inforUser.role !== 'super admin' && <TableCell> </TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {!_.isEmpty(listTopics) ? (
                <>
                  {listTopics.map((item: TopicTypes) => (
                    <TableRow
                      key={item.topic_id}
                      hover
                      tabIndex={-1}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell
                        onClick={() => {
                          setTopicSocial(item);
                          navigate('/social/overview');
                        }}
                        sx={{
                          fontWeight: 600,
                          cursor: 'pointer',
                          color: 'primary.main',
                          '&:hover': {
                            color: 'text.primary',
                            textDecoration: 'underline',
                          },
                        }}
                      >
                        {item.topic_name}
                      </TableCell>
                      <TableCell>
                        {item.key_word_main.map((keyword: string) => (
                          <Chip
                            variant="filled"
                            label={keyword}
                            key={keyword}
                            sx={{
                              ml: 1,
                              mt: 1,
                              '.MuiChip-label': {
                                fontSize: '1.2rem',
                              },
                            }}
                          />
                        ))}
                      </TableCell>
                      <TableCell>{item.created_at}</TableCell>
                      <TableCell>{item.created_at !== item.modified_at ? item.modified_at : '-'}</TableCell>
                      {inforUser.role !== 'super admin' && (
                        <TableCell sx={{ textAlign: 'center' }}>
                          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Tooltip title={t('tooltip.edit')} placement="bottom">
                              <Button
                                variant="contained"
                                color="inherit"
                                sx={{
                                  ml: 1,
                                  minWidth: '45px',
                                }}
                                onClick={() => {
                                  setTopicSelected(item);
                                  setOpenDialogUpdate(true);
                                }}
                              >
                                <EditIcon
                                  sx={{
                                    color: 'info.main',
                                    cursor: 'pointer',
                                  }}
                                />
                              </Button>
                            </Tooltip>
                            <Tooltip title={t('tooltip.delete')} placement="bottom">
                              <Button
                                variant="contained"
                                color="inherit"
                                sx={{
                                  ml: 1,
                                  minWidth: '45px',
                                }}
                                onClick={() => {
                                  setTopicSelected(item);
                                  setOpenDialogDelete(true);
                                }}
                              >
                                <DeleteIcon
                                  sx={{
                                    color: 'error.main',
                                    cursor: 'pointer',
                                  }}
                                />
                              </Button>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </>
              ) : (
                <>
                  {!loadingFilter && (
                    <TableRow>
                      <TableCell colSpan={5} sx={{ textAlign: 'center', color: 'text.secondary' }}>
                        {t('notify.noData')}
                      </TableCell>
                    </TableRow>
                  )}
                </>
              )}
            </>
          </TableBody>
        </Table>
        {!loadingFilter && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
            <Pagination
              count={Math.ceil(totalTopic / PAGE_SIZE)}
              color="primary"
              onChange={(event, value) => {
                handleChangePage(value);
              }}
              page={Number(searchParams.get('page') || 1)}
            />
          </Box>
        )}
      </TableContainer>
      <UpdateTopic
        openDialogUpdate={openDialogUpdate}
        onOpenDialogUpdateChange={setOpenDialogUpdate}
        detail={topicSelected ?? ({} as TopicTypes)}
        handleGetListTopic={handleGetListTopic}
      />
      <ConfirmDeleteModal
        openDialogDelete={openDialogDelete}
        onOpenDialogDeleteChange={setOpenDialogDelete}
        handleDelete={handleDelete}
      />
    </>
  );
};
export default TableTopic;
