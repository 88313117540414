import DescriptionIcon from '@mui/icons-material/Description';
import GridViewIcon from '@mui/icons-material/GridView';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import PolicyIcon from '@mui/icons-material/Policy';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import SummarizeIcon from '@mui/icons-material/Summarize';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Typography, Box } from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import ItemSideBar from './ItemSideBar';

interface SideBarProps {
  onOpenChange?: (newValue: boolean) => void;
}

const SideBar: React.FC<SideBarProps> = ({ onOpenChange }) => {
  const { t } = useTranslation();

  return (
    <>
      <ItemSideBar
        onOpenChange={onOpenChange}
        icon={TrendingUpIcon}
        text={t('smcc.trending')}
        value="/social/trending"
      />
      <ItemSideBar onOpenChange={onOpenChange} icon={GridViewIcon} text={t('smcc.topic')} value="/social/topic" />
      <ItemSideBar
        onOpenChange={onOpenChange}
        icon={DescriptionIcon}
        text={t('smcc.statisticsOverview')}
        value="/social/statistics-overview"
      />

      <Box
        sx={{
          mt: 1,
          padding: '5px 10px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '1.2rem',
              whiteSpace: 'nowrap',
              color: 'text.secondary',
              fontWeight: 500,
            }}
          >
            {t('smcc.tool')}
          </Typography>
          <KeyboardArrowDownOutlinedIcon sx={{ fontSize: '2rem', color: 'text.disabled', ml: 1.5 }} />
        </Box>
        <Box sx={{ ml: 0.5 }}>
          <ItemSideBar
            onOpenChange={onOpenChange}
            icon={InsertChartOutlinedIcon}
            text={t('smcc.statistics')}
            value="/social/overview"
          />
          <ItemSideBar
            onOpenChange={onOpenChange}
            icon={SmsOutlinedIcon}
            text={t('smcc.posts')}
            value="/social/posts"
          />
          <ItemSideBar
            onOpenChange={onOpenChange}
            icon={PolicyIcon}
            text={t('smcc.analysisKeyword')}
            value="/social/analysis-keyword"
          />
          <ItemSideBar
            onOpenChange={onOpenChange}
            icon={SummarizeIcon}
            text={t('smcc.report')}
            value="/social/report-topic"
          />
        </Box>
      </Box>
    </>
  );
};
export default SideBar;
