import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Autocomplete,
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

import CancelButton from '@/components/common/Button/CancelButton';
import PrimaryButton from '@/components/common/Button/PrimaryButton';
import { Transition } from '@/components/common/Transition';
import ConfirmCancelModal from '@/components/Modal/ConfirmCancelModal';
import useSocialApi from '@/Hooks/api/useSocial';
import { TopicTypes } from '@/types/Social';
import { decrypt } from '@/utils/crypto';

interface UpdateTopicProps {
  openDialogUpdate: boolean;
  onOpenDialogUpdateChange: (newValue: boolean) => void;
  detail: TopicTypes;
  handleGetListTopic: () => void;
}

const UpdateTopic: React.FC<UpdateTopicProps> = ({
  openDialogUpdate,
  onOpenDialogUpdateChange,
  detail,
  handleGetListTopic,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { handleUpdateTopic } = useSocialApi();
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));
  const [searchParams, setSearchParams] = useSearchParams();

  const [inputValue, setInputValue] = useState('');
  const [openDialogCancel, setOpenDialogCancel] = useState(false);

  const updateSuccess = () => {
    onOpenDialogUpdateChange(false);
    reset();
    if (Array.from(searchParams.keys()).length === 1 && searchParams.get('page') === '1') {
      handleGetListTopic();
    } else {
      const newParams = new URLSearchParams(searchParams);
      newParams.set('page', '1');
      setSearchParams(newParams);
    }
  };

  const validationSchema = yup.object().shape({
    topicName: yup.string().required(t('validation.fieldRequired')),
    keywords: yup.array().min(1, t('validation.fieldRequired')),
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (detail) {
      reset({
        topicName: detail.topic_name,
        keywords: detail.key_word_main,
      });
    }
  }, [detail, reset]);
  const onSubmit = async (data: any) => {
    const params = {
      topic_id: detail.topic_id,
      topic_name: data.topicName,
      key_word_main: data.keywords,
      group_id: inforUser.groupId,
    };
    await handleUpdateTopic(params, updateSuccess);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputValue) {
      event.preventDefault();
      setValue('keywords', [...(getValues('keywords') || []), inputValue], { shouldValidate: true });
      setInputValue('');
    }
  };

  const handleCancelClick = () => {
    if (isDirty) {
      setOpenDialogCancel(true);
    } else {
      onOpenDialogUpdateChange(false);
    }
  };

  const handleCancel = () => {
    onOpenDialogUpdateChange(false);
    setOpenDialogCancel(false);
    reset();
  };

  return (
    <Dialog
      open={openDialogUpdate}
      TransitionComponent={Transition}
      keepMounted
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          return;
        }
        onOpenDialogUpdateChange(false);
      }}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '500px',
          },
        },
        overflow: 'visible',
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <DialogTitle>
        <Typography sx={{ fontSize: '2rem', marginTop: 1 }}>{t('action.updateTopic')}</Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ border: `1px solid ${theme.palette.divider}`, borderRadius: 1, padding: 2 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="topicName"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type="text"
                  style={{ width: '100%' }}
                  sx={{
                    mt: 1,
                  }}
                  label={t('placeholder.topic')}
                  error={!!fieldState.error}
                  helperText={fieldState.error ? fieldState.error.message : null}
                  id="input-with-icon-textfield"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: !!field.value,
                  }}
                  value={field.value || ''}
                />
              )}
            />
            <Controller
              name="keywords"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  multiple
                  id="tags-filled"
                  options={[]}
                  freeSolo
                  value={field.value || []}
                  onChange={(_, value) => field.onChange(value)}
                  getOptionLabel={(option) => option}
                  renderTags={
                    (tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} key={option} />
                      ))
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                  sx={{
                    mt: 3,
                    '.MuiChip-label': {
                      fontSize: '1.4rem',
                    },
                  }}
                  inputValue={inputValue}
                  onInputChange={(_, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={t('placeholder.keyword')}
                      onKeyDown={handleKeyDown}
                      error={!!errors.keywords}
                      helperText={errors.keywords?.message}
                    />
                  )}
                />
              )}
            />

            <Typography sx={{ fontSize: '1.2rem', color: 'text.disabled', mt: 1 }}>{t('smcc.enterToAdd')}</Typography>

            <Box sx={{ margin: 'auto', mt: 2, display: 'flex', justifyContent: 'flex-end', mr: 3 }}>
              <PrimaryButton handleClick={handleSubmit(onSubmit)} label={t('action.update')} />
              <CancelButton handleClick={handleCancelClick} />
            </Box>
            <ConfirmCancelModal
              openDialogCancel={openDialogCancel}
              onOpenDialogCancelChange={setOpenDialogCancel}
              handleCancel={handleCancel}
            />
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default UpdateTopic;
