import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Grid,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  StepLabel,
  Stepper,
  Step,
} from '@mui/material';
import * as _ from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import CancelButton from '@/components/common/Button/CancelButton';
import { Transition } from '@/components/common/Transition';
import ConfirmCancelModal from '@/components/Modal/ConfirmCancelModal';
import useNotify from '@/Hooks/common/useNotify';
import { DomainTypes } from '@/types/management';

interface CreateDomainSuperAdminProps {
  openDialogCreate: boolean;
  onOpenDialogCreateChange: (newValue: boolean) => void;
  onTabChange: (newValue: number) => void;
  listDomain: DomainTypes[];
  onListDomainChange: React.Dispatch<React.SetStateAction<DomainTypes[]>>;
}

const CreateDomainSuperAdmin: React.FC<CreateDomainSuperAdminProps> = ({
  openDialogCreate,
  onOpenDialogCreateChange,
  onTabChange,
  listDomain,
  onListDomainChange,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { onNotify } = useNotify();

  const steps = [t('action.addClient'), t('action.addUser')];
  const [openDialogCancel, setOpenDialogCancel] = useState(false);

  const validationSchema = yup.object().shape({
    companyName: yup.string().required(t('validation.fieldRequired')),
    mainDomain: yup
      .string()
      .required(t('validation.fieldRequired'))
      .matches(/^(?!:\/\/)([a-zA-Z0-9-]+\.)+([a-zA-Z]{2,})(:[0-9]+)?(\/[^\s]*)?$/, t('validation.invalidDomain')),
    clientName: yup.string().required(t('validation.fieldRequired')),
    country: yup.string().required(t('validation.fieldRequired')),
    address: yup.string().required(t('validation.fieldRequired')),
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      companyName: '',
      mainDomain: '',
      clientName: '',
      country: '',
      address: '',
    },
  });

  const onSubmit = (data: any) => {
    const newDomain = {
      name_company: data.companyName.trim(),
      name_client: data.clientName.trim(),
      main_domain: data.mainDomain.trim(),
      country: data.country.trim(),
      address: data.address.trim(),
    };
    const isUsernameExists = listDomain.some((user) => user.main_domain === newDomain.main_domain);
    if (isUsernameExists) {
      onNotify('warning', t('notify.duplicateDomain'), 3000);
    } else {
      onListDomainChange((prevList) => [...prevList, newDomain]);
      reset();
    }
  };

  const handleCancelClick = () => {
    if (isDirty || !_.isEmpty(listDomain)) {
      setOpenDialogCancel(true);
    } else {
      onOpenDialogCreateChange(false);
      reset();
    }
  };

  const handleCancel = () => {
    onOpenDialogCreateChange(false);
    setOpenDialogCancel(false);
    reset();
    onListDomainChange([]);
  };

  return (
    <>
      <Dialog
        open={openDialogCreate}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
          onOpenDialogCreateChange(false);
        }}
        disableEscapeKeyDown
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '800px',
            },
          },
          overflow: 'visible',
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <DialogTitle>
          <Stepper activeStep={0} alternativeLabel>
            {steps.map((label) => (
              <Step sx={{ mt: 2 }} key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </DialogTitle>

        <DialogContent>
          <Box sx={{ borderRadius: 2, mt: 1, padding: 2, backgroundColor: 'action.hover' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
              }}
            >
              {!_.isEmpty(listDomain) && (
                <>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      type="button"
                      variant="contained"
                      color="success"
                      onClick={() => {
                        onTabChange(1);
                      }}
                      sx={{
                        textTransform: 'initial',
                        fontSize: '1.2rem',
                        fontWeight: '500',
                        borderRadius: '5px',
                      }}
                    >
                      {t('action.continue')}
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      border: `1px solid ${theme.palette.divider}`,
                      padding: 1,
                      borderRadius: '10px',
                      mb: 2,
                      mt: 2,
                    }}
                  >
                    <TableContainer>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t('table.company.company')}</TableCell>
                            <TableCell>{t('table.company.client')}</TableCell>
                            <TableCell>{t('table.company.domain')}</TableCell>
                            <TableCell>{t('table.company.country')}</TableCell>
                            <TableCell>{t('table.company.address')}</TableCell>
                            <TableCell> </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {listDomain.map((row: DomainTypes) => (
                            <TableRow key={row.main_domain} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell>{row.name_company}</TableCell>
                              <TableCell>{row.name_client}</TableCell>
                              <TableCell>{row.main_domain}</TableCell>
                              <TableCell>{row.country}</TableCell>
                              <TableCell>{row.address}</TableCell>
                              <TableCell component="th" scope="row">
                                <Tooltip title={t('tooltip.delete')} placement="bottom">
                                  <DeleteIcon
                                    onClick={() => {
                                      onListDomainChange((prevList: DomainTypes[]) =>
                                        prevList.filter((domain) => domain.main_domain !== row.main_domain),
                                      );
                                    }}
                                    sx={{
                                      cursor: 'pointer',
                                      '&:hover': {
                                        color: 'error.main',
                                      },
                                    }}
                                  />
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </>
              )}

              <form onSubmit={handleSubmit(onSubmit)}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    padding: 1,
                    borderRadius: '10px',
                    border: `1px solid ${theme.palette.divider}`,
                  }}
                >
                  <Grid container spacing={2} sx={{ marginTop: { md: 0.5, xs: 0 } }}>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="companyName"
                        control={control}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            type="text"
                            style={{ width: '100%' }}
                            label={t('placeholder.companyName')}
                            error={!!fieldState.error}
                            helperText={fieldState.error ? fieldState.error.message : null}
                            id="input-with-icon-textfield"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="clientName"
                        control={control}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            type="text"
                            style={{ width: '100%' }}
                            label={t('placeholder.clientName')}
                            error={!!fieldState.error}
                            helperText={fieldState.error ? fieldState.error.message : null}
                            id="input-with-icon-textfield"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginTop: { md: 1, xs: 0 } }}>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="mainDomain"
                        control={control}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            type="text"
                            style={{ width: '100%' }}
                            label={t('placeholder.mainDomain')}
                            error={!!fieldState.error}
                            helperText={fieldState.error ? fieldState.error.message : null}
                            id="input-with-icon-textfield"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="country"
                        control={control}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            type="text"
                            style={{ width: '100%' }}
                            label={t('placeholder.country')}
                            error={!!fieldState.error}
                            helperText={fieldState.error ? fieldState.error.message : null}
                            id="input-with-icon-textfield"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginTop: { md: 1, xs: 0 }, mb: 2 }}>
                    <Grid item xs={12}>
                      <Controller
                        name="address"
                        control={control}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            type="text"
                            multiline
                            style={{ width: '100%' }}
                            label={t('placeholder.address')}
                            error={!!fieldState.error}
                            helperText={fieldState.error ? fieldState.error.message : null}
                            id="input-with-icon-textfield"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </form>
              <Box sx={{ margin: 'auto', mt: 2, display: 'flex', justifyContent: 'flex-start', mr: 3 }}>
                <Button
                  variant="contained"
                  color="info"
                  type="submit"
                  name="addMoreDomain"
                  onClick={handleSubmit(onSubmit)}
                  sx={{
                    textTransform: 'initial',
                    fontWeight: '500',
                    borderRadius: '5px',
                  }}
                >
                  {t('action.addClient')}
                </Button>

                <CancelButton handleClick={handleCancelClick} />
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <ConfirmCancelModal
        openDialogCancel={openDialogCancel}
        onOpenDialogCancelChange={setOpenDialogCancel}
        handleCancel={handleCancel}
      />
    </>
  );
};
export default CreateDomainSuperAdmin;
