import { useState, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import SearchIcon from '@mui/icons-material/Search';
import { Box, TextField, Typography, SelectChangeEvent } from '@mui/material';
import * as _ from 'lodash';
import { Helmet } from 'react-helmet';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import FormSelectDomain from '@/components/common/FormSelectDomain';
import ScanTime from '@/components/common/ScanTime';
import StyledTab from '@/components/Tab/StyledTab';
import StyledTabs from '@/components/Tab/StyledTabs';
import { useCommonInfo } from '@/contexts/Common';
import { useWebSocketContext } from '@/contexts/WebSocketContext';
import useAsm from '@/Hooks/api/useAsm';
import useLoading from '@/Hooks/common/useLoading';
import useSetKeySearch from '@/Hooks/common/useSetKeySearch';
import { decrypt, encrypt } from '@/utils/crypto';

import AsnDetail from './AsnDetail';
import DNSRecordsDetail from './DNSRecords';
import SslDetail from './SslDetail';
import WhoisDetail from './WhoisDetail';

const Info = () => {
  const { t } = useTranslation();
  const { keySearch } = useCommonInfo();
  const domainActive = localStorage.getItem('domainActive')
    ? decrypt(localStorage.getItem('domainActive') as string)
    : '';
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));
  const { dataWebSocket } = useWebSocketContext();

  const { setLoading } = useLoading();
  const { setKeySearch } = useSetKeySearch();
  const { handleSearchDns, handleSearchInfo, handleSearchSsl } = useAsm();

  const [key, setKey] = useState(keySearch);
  const [dns, setDns] = useState<any>({ data: {}, update_time: '' });
  const [info, setInfo] = useState<any>({ data: {}, update_time: '' });
  const [ssl, setSsl] = useState<any>({ data: [], update_time: '' });
  const [valueTab, setValueTab] = useState(0);
  const [domain, setDomain] = useState(domainActive);
  const [subdomain, setSubdomain] = useState('');

  const filteredList = dataWebSocket.filter((item: any) => item.domain === domain);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValueTab(newValue);
  };

  const handleGetInformation = async (param: string) => {
    const params = {
      domain: param,
    };
    setLoading(true);
    const [dnsRes, infoRes, sslRes] = await Promise.all([
      handleSearchDns(params),
      handleSearchInfo(params),
      handleSearchSsl(params),
    ]);
    setLoading(false);

    setDns({ data: dnsRes.data, update_time: '' });
    setInfo({ data: infoRes.data, update_time: '' });
    setSsl({ data: sslRes.data, update_time: '' });
    setKeySearch('');
  };

  useEffect(() => {
    if (keySearch) {
      setValue('searchKey', keySearch);
      handleGetInformation(keySearch);
      setSubdomain(keySearch);
    }
  }, [keySearch]);

  useEffect(() => {
    if (inforUser?.role !== 'super admin' && keySearch === '') {
      const dnsWebsocket = filteredList.find((item: any) => item.title === 'dns');
      const whoisWebsocket = filteredList.find((item: any) => item.title === 'whois');
      const sslWebsocket = filteredList.find((item: any) => item.title === 'ssl');
      if (!_.isEmpty(dnsWebsocket?.data) || !_.isEmpty(whoisWebsocket?.data) || !_.isEmpty(sslWebsocket?.data)) {
        setDns({ data: dnsWebsocket?.data?.data || {}, update_time: dnsWebsocket?.update_time });
        setInfo({ data: whoisWebsocket?.data?.data || {}, update_time: whoisWebsocket?.update_time });
        setSsl({ data: sslWebsocket?.data?.data || {}, update_time: sslWebsocket?.update_time });
        setLoading(false);
      } else {
        setLoading(true);
      }
    }
  }, [domain, dataWebSocket]);

  const validationSchema = yup.object({
    searchKey: yup.string().required(t('validation.fieldRequired')),
  });

  const { control, handleSubmit, setValue } = useForm({ resolver: yupResolver(validationSchema) });

  const onSubmit = (data: any) => {
    setKey(data.searchKey?.trim());
    handleGetInformation(data.searchKey?.trim());
  };

  const handleKeyDown = (event: { keyCode: number }) => {
    if (event.keyCode === 13) {
      handleSubmit(onSubmit);
    }
  };

  const handleChangeDomain = async (event: SelectChangeEvent) => {
    setDomain(event.target.value);
    localStorage.setItem('domainActive', await encrypt(event.target.value));
    setSubdomain('');
    setKeySearch('');
  };

  return (
    <>
      <Helmet>
        <title>Information Domain - Attack Surface Management</title>
      </Helmet>
      <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.primary' }}>
        {t('asm.information')}
      </Typography>
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <StyledTabs
            value={valueTab}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            <StyledTab
              sx={{
                '&.MuiTab-root': {
                  fontSize: '1.6rem',
                },
              }}
              label="DNS"
            />
            <StyledTab
              sx={{
                '&.MuiTab-root': {
                  fontSize: '1.6rem',
                },
              }}
              label="ASN"
            />
            <StyledTab
              sx={{
                '&.MuiTab-root': {
                  fontSize: '1.6rem',
                },
              }}
              label="Whois"
            />
            <StyledTab
              sx={{
                '&.MuiTab-root': {
                  fontSize: '1.6rem',
                },
              }}
              label="SSL"
            />
          </StyledTabs>
        </Box>
        {inforUser?.role === 'super admin' ? (
          <Box sx={{ width: '100%', mt: 2 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="searchKey"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    autoComplete="off"
                    variant="outlined"
                    style={{ width: '100%' }}
                    placeholder={t('placeholder.mainDomain')}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                    onKeyDown={handleKeyDown}
                    sx={{
                      '.MuiInputBase-input': {
                        padding: '12px 12px',
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <SearchIcon
                          onClick={handleSubmit(onSubmit)}
                          sx={{
                            fontSize: '2rem',
                            mr: 1,
                            '&:hover': {
                              cursor: 'pointer',
                            },
                          }}
                        />
                      ),
                    }}
                  />
                )}
              />
            </form>
          </Box>
        ) : (
          <Box sx={{ width: '100%', mt: 2, display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex' }}>
              <FormSelectDomain domain={domain} handleChangeDomain={handleChangeDomain} />
              {subdomain && (
                <Box sx={{ display: 'flex', ml: 4, alignSelf: 'center', paddingBottom: '5px' }}>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Subdomain :
                  </Typography>
                  <Typography variant="body2" sx={{ marginLeft: 1 }}>
                    {subdomain}
                  </Typography>
                </Box>
              )}
            </Box>

            {valueTab === 0 && !_.isEmpty(dns?.update_time) && subdomain === '' && (
              <ScanTime updateTime={dns?.update_time} />
            )}
            {(valueTab === 1 || valueTab === 2) && !_.isEmpty(info?.update_time) && subdomain === '' && (
              <ScanTime updateTime={info?.update_time} />
            )}
            {valueTab === 3 && !_.isEmpty(ssl?.update_time) && subdomain === '' && (
              <ScanTime updateTime={ssl?.update_time} />
            )}
          </Box>
        )}
        <Box sx={{ mt: 3 }}>
          {valueTab === 0 && <DNSRecordsDetail dns={dns.data} />}
          {valueTab === 1 && <AsnDetail info={info.data} />}
          {valueTab === 2 && <WhoisDetail info={info.data} />}
          {valueTab === 3 && <SslDetail ssl={ssl.data} />}
        </Box>
      </Box>
    </>
  );
};
export default Info;
