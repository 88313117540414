import React, { useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ListAltIcon from '@mui/icons-material/ListAlt';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import {
  Button,
  TableContainer,
  useTheme,
  TableHead,
  Table,
  TableCell,
  tableCellClasses,
  styled,
  TableRow,
  TableBody,
  Tooltip,
  Skeleton,
  Box,
  Pagination,
} from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ConfirmDeleteModal from '@/components/Modal/ConfirmDeleteModal';
import usePhishing from '@/Hooks/api/usePhishing';
import { CampaignTypes } from '@/types/phishing';
import { PAGE_SIZE } from '@/utils/constants';
import { decrypt } from '@/utils/crypto';
import { fDateTime } from '@/utils/function';

import DetailCampaign from './DetailCampaign';
import UpdateCampaign from './UpdateCampaign';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'rgba(255, 255, 255, 0.06)',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  borderBottom: 'none',
}));

interface TableCampaignProps {
  campaign: CampaignTypes[];
  totalCampaign: number;
  handleGetListCampaign: () => void;
  loadingFilter?: boolean;
}

const TableCampaign: React.FC<TableCampaignProps> = ({
  campaign,
  loadingFilter,
  handleGetListCampaign,
  totalCampaign,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));
  const { handleDeleteDetailCampaign, handleRunDetailCampaign } = usePhishing();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [openDialogDetail, setOpenDialogDetail] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [openDialogUpdate, setOpenDialogUpdate] = useState(false);
  const [idDelete, setIdDelete] = useState('');
  const [campaignSelected, setCampaignSelected] = useState<CampaignTypes>();

  const deleteCampaignSuccess = () => {
    setOpenDialogDelete(false);
    if (Array.from(searchParams.keys()).length === 1 && searchParams.get('page') === '1') {
      handleGetListCampaign();
    } else {
      const newParams = new URLSearchParams(searchParams);
      newParams.set('page', '1');
      setSearchParams(newParams);
    }
  };

  const handleDeleteCampaign = () => {
    handleDeleteDetailCampaign(idDelete, deleteCampaignSuccess);
  };

  const handleRunCampaign = (value: string) => {
    handleRunDetailCampaign(value, {});
  };

  const handleChangePage = (pageValue: number) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set('page', String(pageValue));
    navigate({ search: newParams.toString() });
  };

  return (
    <TableContainer
      sx={{
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '5px',
      }}
    >
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{ width: '20%' }}>{t('asm.phishing.campaignName')}</StyledTableCell>
            <StyledTableCell sx={{ width: '30%' }}>{t('asm.phishing.description')}</StyledTableCell>
            <StyledTableCell sx={{ width: '20%', whiteSpace: 'nowrap' }}>{t('asm.phishing.createAt')}</StyledTableCell>
            <StyledTableCell sx={{ width: '20%', whiteSpace: 'nowrap' }}>
              {t('asm.phishing.modifiedAt')}
            </StyledTableCell>
            {inforUser.role !== 'member' && <StyledTableCell sx={{ width: '30%' }}> </StyledTableCell>}
          </TableRow>
        </TableHead>

        {loadingFilter ? (
          <TableBody>
            <TableRow>
              <TableCell>
                <Skeleton height={20} width="20%" />
              </TableCell>
              <TableCell>
                <Skeleton height={20} width="20%" />
              </TableCell>
              <TableCell>
                <Skeleton height={20} width="20%" />
              </TableCell>
              <TableCell>
                <Skeleton height={20} width="20%" />
              </TableCell>
              {inforUser.role !== 'member' && (
                <TableCell>
                  <Skeleton height={20} width="20%" />
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {!_.isEmpty(campaign) ? (
              <>
                {campaign?.map((row: CampaignTypes) => (
                  <React.Fragment key={row.id}>
                    <TableRow
                      sx={{
                        verticalAlign: 'baseline',
                      }}
                      hover
                    >
                      <StyledTableCell sx={{ width: '20%', whiteSpace: 'nowrap' }}>{row.name}</StyledTableCell>
                      <StyledTableCell sx={{ width: '30%', whiteSpace: 'nowrap' }}>{row.description}</StyledTableCell>
                      <StyledTableCell sx={{ width: '20%', whiteSpace: 'nowrap' }}>
                        {fDateTime(row.created_at)}
                      </StyledTableCell>
                      <StyledTableCell sx={{ width: '20%', whiteSpace: 'nowrap' }}>
                        {row.created_at !== row.modified_at ? fDateTime(row.modified_at) : '-'}
                      </StyledTableCell>
                      {inforUser.role !== 'member' && (
                        <StyledTableCell sx={{ width: '30%', whiteSpace: 'nowrap', textAlign: 'end' }}>
                          <Box sx={{ display: 'flex' }}>
                            <Tooltip title={t('tooltip.launch')} placement="bottom">
                              <Button
                                variant="contained"
                                color="inherit"
                                sx={{
                                  minWidth: '45px',
                                }}
                                onClick={() => {
                                  handleRunCampaign(row.id);
                                }}
                              >
                                <RocketLaunchIcon
                                  sx={{
                                    color: 'success.main',
                                    cursor: 'pointer',
                                  }}
                                />
                              </Button>
                            </Tooltip>

                            <Tooltip title={t('tooltip.detail')} placement="bottom">
                              <Button
                                variant="contained"
                                color="inherit"
                                sx={{
                                  ml: 1,
                                  minWidth: '45px',
                                }}
                                onClick={() => {
                                  setOpenDialogDetail(true);
                                  setCampaignSelected(row);
                                }}
                              >
                                <ListAltIcon
                                  sx={{
                                    color: 'primary.main',
                                    cursor: 'pointer',
                                  }}
                                />
                              </Button>
                            </Tooltip>

                            <Tooltip title={t('tooltip.edit')} placement="bottom">
                              <Button
                                variant="contained"
                                color="inherit"
                                sx={{
                                  ml: 1,
                                  minWidth: '45px',
                                }}
                                onClick={() => {
                                  setOpenDialogUpdate(true);
                                  setCampaignSelected(row);
                                }}
                              >
                                <EditIcon
                                  sx={{
                                    color: 'secondary.main',
                                    cursor: 'pointer',
                                  }}
                                />
                              </Button>
                            </Tooltip>

                            <Tooltip title={t('tooltip.delete')} placement="bottom">
                              <Button
                                variant="contained"
                                color="inherit"
                                sx={{
                                  ml: 1,
                                  minWidth: '45px',
                                }}
                                onClick={() => {
                                  setOpenDialogDelete(true);
                                  setIdDelete(row.id);
                                }}
                              >
                                <DeleteIcon
                                  sx={{
                                    color: 'error.main',
                                    cursor: 'pointer',
                                  }}
                                />
                              </Button>
                            </Tooltip>
                          </Box>
                        </StyledTableCell>
                      )}
                    </TableRow>
                  </React.Fragment>
                ))}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={5} sx={{ textAlign: 'center', color: 'text.secondary' }}>
                  {t('notify.noData')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>
      {!loadingFilter && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2, marginBottom: 2 }}>
          <Pagination
            count={Math.ceil(totalCampaign / PAGE_SIZE)}
            color="primary"
            onChange={(event, value) => {
              handleChangePage(value);
            }}
            page={Number(searchParams.get('page') || 1)}
          />
        </Box>
      )}
      <DetailCampaign
        openDialogDetail={openDialogDetail}
        setOpenDialogDetail={setOpenDialogDetail}
        campaign={campaignSelected ?? ({} as CampaignTypes)}
      />
      <UpdateCampaign
        openDialogUpdate={openDialogUpdate}
        onOpenDialogUpdateChange={setOpenDialogUpdate}
        campaign={campaignSelected ?? ({} as CampaignTypes)}
        handleGetListCampaign={handleGetListCampaign}
      />
      <ConfirmDeleteModal
        openDialogDelete={openDialogDelete}
        onOpenDialogDeleteChange={setOpenDialogDelete}
        handleDelete={handleDeleteCampaign}
      />
    </TableContainer>
  );
};
export default TableCampaign;
