import React from 'react';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  handleClick: () => void;
}
const CancelButton: React.FC<Props> = ({ handleClick }) => {
  const { t } = useTranslation();
  return (
    <Button
      variant="outlined"
      fullWidth
      type="button"
      onClick={handleClick}
      sx={{
        width: '90px',
        fontWeight: '500',
        borderRadius: '5px',
        textTransform: 'none',
        transition: 'all .1s ease-in-out',
        '&:active': {
          transform: 'translate(0, calc(-5 * 1%)) scale(0.98)',
          transition: 'all .1s ease-in-out',
        },
        whiteSpace: 'nowrap',
        alignSelf: 'center',
        ml: '20px !important',
      }}
    >
      {t('action.cancel')}
    </Button>
  );
};
export default CancelButton;
