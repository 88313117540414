import React from 'react';

import { Typography, Button, Dialog, DialogContent, DialogActions, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Transition } from '../common/Transition';

interface ConfirmCancelModalProps {
  openDialogCancel: boolean;
  onOpenDialogCancelChange: (newValue: boolean) => void;
  handleCancel: () => void;
}

const ConfirmCancelModal: React.FC<ConfirmCancelModalProps> = ({
  openDialogCancel,
  onOpenDialogCancelChange,
  handleCancel,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Dialog
      open={openDialogCancel}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        onOpenDialogCancelChange(false);
      }}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '400px',
            backgroundColor: 'background.dark',
            border: `1px solid ${theme.palette.divider}`,
            boxShadow: 'none',
          },
        },
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <DialogContent>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          {t('notify.unsaved')}
        </Typography>
        <Typography fontSize={16} sx={{ mt: 2 }}>
          {t('notify.confirmCancel')}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ mb: 1, mr: 3 }}>
        <Button
          onClick={() => {
            handleCancel();
          }}
          variant="contained"
          sx={{
            textTransform: 'initial',
          }}
        >
          {t('action.confirm')}
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            onOpenDialogCancelChange(false);
          }}
          sx={{
            ml: '20px !important',
            textTransform: 'initial',
          }}
        >
          {t('action.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmCancelModal;
